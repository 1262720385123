import React, { useState, useEffect } from "react";
import CommonHeader from "../Common/CommonHeader";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CommonContainer from "../Common/CommonContainer";
import { CommonSpinner } from "../Common/CommonLoading";
import FailureModal from "../FailureModal";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import { Review } from "../../models/Review";
import { usePortalService } from "../../contexts/PortalContext";
import { Portal } from "../../models/Portal";
import { PortalVersion } from "../../models/PortalVersion";
import Icon from "../Icon";
import { ReviewModal } from "./ReviewModal";
import { AppPath } from "../../models/AppPath";

interface ReviewsProps {}

const Reviews: React.FC<ReviewsProps> = ({}) => {
  const { teamId, portalId, versionId, reviewId } = useParams<{
    teamId: string;
    portalId: string;
    versionId: string;
    reviewId: string;
  }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const timestamp = searchParams.get("time")
    ? Number(searchParams.get("time"))
    : undefined;

  const [error, setError] = useState("");

  const portalService = usePortalService();
  const navigate = useNavigate();

  const [portal, setPortal] = useState<Portal>();
  const [version, setVersion] = useState<PortalVersion>();
  const [reviews, setReviews] = useState<Review[]>([]);
  const [loading, setLoading] = useState(true);
  const [nextState, setNextState] = useState<AnimationState>("ready");
  const [backState, setBackState] = useState<AnimationState>("ready");
  const limit = 50;

  const loadData = async () => {
    try {
      setLoading(true);

      const getPortal = portalService.portalRepo.get(
        portalService.portalPath(teamId!),
        portalId!
      );
      const getVersion = portalService.portalVersionRepo.get(
        portalService.portalVersionPath(teamId!, portalId!),
        versionId!
      );
      const getReviews = portalService.reviewRepo.getList(
        portalService.reviewPath(teamId!, portalId!, versionId!),
        { name: "createdAt", descending: true },
        undefined,
        limit,
        timestamp ? new Date(timestamp) : undefined
      );

      const [portal, version, reviews] = await Promise.all([
        getPortal,
        getVersion,
        getReviews,
      ]);

      setReviews(reviews);
      setPortal(portal!);
      setVersion(version!);
    } catch (e) {
      setError(e instanceof Error ? e.message : "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [timestamp]);

  const handleNextPage = () => {
    const startAfter = reviews[reviews.length - 1].createdAt;
    setSearchParams({
      time: `${startAfter.getTime()}`,
    });
    setReviews([]);
  };
  const handleBack = () => {
    setReviews([]);
    navigate(-1);
  };

  const handleRowClick = (review: Review) => {
    navigate(review.id!);
  };

  const portalName = portal?.name ?? "Prompt";
  const versionName = version?.name ?? "Version";

  const scoreLabel = (
    <div id="tokenLabel" key="tokenLabel" className="flex flex-col gap-2">
      <div className="w-full flex justify-end flex-row gap-1">
        <div className={`text-gray-400 text-md font-normal justify-end`}>
          Average Score
        </div>
        <Icon type="chart-pie" className="text-gray-200" />
      </div>

      <div className="w-full flex justify-end">
        <div
          className={`text-transparent bg-clip-text bg-gradient-to-br from-green-500 to-blue-500 text-4xl font-medium font-gooper`}
        >
          {`${Math.round((version?.averageReviewScore ?? 0) * 10) / 10}`}
        </div>
      </div>
    </div>
  );

  return (
    <CommonContainer>
      <CommonHeader
        title={`Reviews`}
        subtitle={`Reviews for ${versionName} of ${portalName}`}
        sections={[
          { name: "Prompts", link: AppPath.portals(teamId!) },
          {
            name: `${portalName}`,
            link: AppPath.portal(teamId!, portalId!),
          },
          {
            name: `${versionName}`,
            link: AppPath.portalVersion(teamId!, portalId!, versionId!),
          },
          {
            name: `Reviews`,
            link: ``,
          },
        ]}
        teamId={teamId!}
        actions={[
          version?.averageReviewScore == undefined ? (
            <CommonSpinner key="tokenSpinner" />
          ) : (
            scoreLabel
          ),
        ]}
      />
      <FailureModal
        shows={error != ""}
        message={error}
        closed={() => setError("")}
      />
      <ReviewModal
        teamId={teamId!}
        portalId={portalId!}
        versionId={versionId!}
        reviewId={reviewId}
      />
      <div className="">
        <table className="border rounded-lg border-gray-500 w-full overflow-hidden bg-gray-200">
          <thead className="border-b border-gray-200">
            <tr className="">
              <Header title="Score" />
              <Header title="Created" />
              <Header title="Text" className="" />
              <Header title="User ID" className="" />
            </tr>
          </thead>
          {loading && (
            <tbody>
              <tr className="bg-gray-0 hover:bg-gray-100">
                <td className="border-b border-l border-gray-200 p-3 font-medium text-sm">
                  <CommonSpinner />
                </td>
                <td className="border-b border-gray-200 p-3 font-medium text-sm">
                  <CommonSpinner />
                </td>
                <td className="border-b  border-gray-200 p-3 font-medium text-sm">
                  <CommonSpinner />
                </td>
                <td className="border-b border-r border-gray-200 p-3 font-medium text-sm">
                  <CommonSpinner />
                </td>
              </tr>
            </tbody>
          )}
          <tbody>
            {reviews.map((review) => {
              return (
                <tr
                  key={review.id}
                  className={`hover:bg-gray-100 bg-gray-0`}
                  onClick={() => handleRowClick(review)}
                >
                  <td className="border-b border-gray-200 p-3 font-medium text-sm hidden md:table-cell">
                    {review.score}
                  </td>
                  <td className="border-b p-3 text-sm text-gray-500 font-medium">
                    {new Date(review.createdAt)
                      .toLocaleDateString("en-GB", {
                        second: "numeric",
                        minute: "numeric",
                        hour: "numeric",
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })
                      .replace(",", "")}
                  </td>
                  <td
                    className="border-b border-gray-200 p-3 font-medium text-sm md:table-cell truncate overflow-hidden"
                    style={{ maxWidth: "200px" }}
                  >
                    {review.text}
                  </td>

                  <td className="border-b border-gray-200 p-3 font-medium text-sm hidden md:table-cell">
                    {review.userId}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="flex flex-row justify-between">
        <AnimatedButton
          title="Back"
          onClick={handleBack}
          buttonState={backState}
          setButtonState={setBackState}
          style="action"
          font="font-sans"
          classNameIn={`${timestamp ? "" : "invisible"}`}
        />
        <AnimatedButton
          title="Next"
          onClick={handleNextPage}
          buttonState={nextState}
          setButtonState={setNextState}
          style="action"
          font="font-sans"
          classNameIn={`${reviews.length == limit ? "" : "invisible"}`}
        />
      </div>
    </CommonContainer>
  );
};

export default Reviews;

const Header: React.FC<{ title: string; className?: string }> = ({
  title,
  className,
}) => {
  return (
    <th
      className={`p-3 text-left bg-gray-50 p4 text-xs font-medium text-gray-500 uppercase tracking-widest ${className}`}
    >
      {title}
    </th>
  );
};
