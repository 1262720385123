import React from "react";

export interface TabProps {
  label: string;
  id: string;
  disabled?: boolean;
}

interface TabComponentProps {
  tabs: TabProps[];
  onTabSelect: (id: string) => void;
  selected: string;
}

export const TabComponent: React.FC<TabComponentProps> = ({
  tabs,
  onTabSelect,
  selected,
}) => {
  return (
    <div>
      <ul className="h-8 gray-200 overflow-clip text-sm font-medium text-center text-gray-700 rounded-lg border border-gray-200 bg-gray-200 flex">
        {tabs.map((tab, index) => (
          <li
            id={`tab${tab.id}`}
            key={tab.id}
            onClick={(e) => {
              if (tab.disabled) {
                return;
              }
              e.preventDefault();
              onTabSelect(tab.id);
            }}
            className={`w-full flex items-center justify-center focus-within:z-10 ${
              tab.disabled
                ? "bg-gray-50 text-gray-500"
                : selected === tab.id
                ? "text-blue-500 bg-blue-50 "
                : "bg-white hover:text-gray-700 hover:bg-gray-50 "
            } ${index != 0 ? "border-gray-200 border-l" : ""}`}
          >
            <div className={`flex items-center justify-center `}>
              <a
                href="#"
                className={`rounded items-center text-center inline-block w-full h-full`}
              >
                {tab.label}
              </a>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
