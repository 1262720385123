import React from "react";
import { TeamSubscription } from "../../models/TeamSubscription";
import { CommonModal } from "../Common/CommonModal";
import AnimatedButton from "../AnimatedButton";
import { useTeams } from "../../contexts/TeamContext";

interface SubscriptionBlockedModalProps {
  subscription?: TeamSubscription;
  teamId: string;
}

export const SubscriptionBlockedModal: React.FC<
  SubscriptionBlockedModalProps
> = ({ subscription, teamId }) => {
  const teamService = useTeams();
  const isBlocked =
    subscription?.currentEvent?.status === "canceled" ||
    subscription?.currentEvent?.status == "paused";
  const [buttonState, setButtonState] = React.useState<"ready" | "loading">(
    "ready"
  );

  const handleUpgrade = async () => {
    setButtonState("loading");
    const url = await teamService.updateSubscription(
      teamId!,
      window.location.href
    );
    window.location.href = url;
    setButtonState("ready");
  };

  const handleSupport = async () => {
    setButtonState("loading");
    if (window.Intercom) {
      window.Intercom("showNewMessage", `I need help with my subscription`);
    } else {
      console.warn("Intercom is not available");
    }
    setButtonState("ready");
  };

  return (
    <CommonModal isOpen={isBlocked} onDismiss={() => {}}>
      <div className="w-full max-w-lg p-6 space-y-6">
        {/* Header */}
        <div className="flex justify-center items-center space-x-4">
          <h2 className="text-xl font-gooper font-semibold text-gray-900">
            Your trial is up!
          </h2>
        </div>

        {/* Content */}
        <div className="space-y-4">
          <p className="text-gray-600">
            Now please add your credit card details to keep delighting your
            users with your awesome AI agents! It's as easy as 1-2-3
          </p>
          <ul className="list-decimal list-inside space-y-2 text-gray-600 ml-4">
            <li>Click the reactivate button below</li>
            <li>Add your payment information</li>
            <li>Click start subscription</li>
          </ul>
        </div>

        {/* Actions */}
        <div className="flex flex-col gap-4 pt-4 border-t border-gray-200">
          <AnimatedButton
            title="Reactivate Subscription"
            buttonState={buttonState}
            onClick={handleUpgrade}
            style="action"
            triggerKey="Enter"
          />

          <AnimatedButton
            title="Contact Support"
            onClick={handleSupport}
            style="transparent-black"
            buttonState="ready"
          />
        </div>
      </div>
    </CommonModal>
  );
};

export default SubscriptionBlockedModal;
