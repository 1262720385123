import React, { useEffect, useRef } from "react";
import Icon from "../../Icon";

interface NodeContextMenuProps {
  x: number;
  y: number;
  onClose: () => void;
  onDelete: () => void;
  onChangeType: () => void;
}

export const ContextMenu: React.FC<NodeContextMenuProps> = ({
  x,
  y,
  onClose,
  onDelete,
  onChangeType,
}) => {
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [onClose]);

  return (
    <div
      ref={menuRef}
      className="fixed bg-white rounded-lg shadow-lg border border-gray-200 py-1 z-50"
      style={{
        left: x,
        top: y,
      }}
    >
      <button
        onClick={onChangeType}
        className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 flex items-center gap-2"
      >
        <Icon type="refresh" className="w-4 h-4" />
        Change Type
      </button>
      <button
        onClick={onDelete}
        className="w-full px-4 py-2 text-left text-sm text-red-600 hover:bg-gray-100 flex items-center gap-2"
      >
        <Icon type="x" className="w-4 h-4" />
        Delete Node
      </button>
    </div>
  );
};

export default ContextMenu;
