import { Mention, MentionsInput } from "react-mentions";
import React, { useRef, useCallback, useEffect } from "react";
import { Node, useReactFlow } from "reactflow";
import { findAvailableConversationalData } from "../../Map/Nodes/NodeUtils";
import {
  ConversationalIcon,
  OutputIcon,
  TeamSecretIcon,
  UserSecretIcon,
  VariableIcon,
} from "./DataIcons";

export const APIBodyInputStyle = {
  control: {
    backgroundColor: "",
    fontSize: 14,
    fontWeight: "normal",
    lineHeight: "20px",
    fontFamily: "inherit",
    boxSizing: "border-box",
  },

  "&multiLine": {
    control: {
      fontFamily: "inherit",
      minHeight: 63,
      lineHeight: "20px",
    },
    highlighter: {
      padding: 9,
      border: "1px solid transparent",
      fontFamily: "inherit",
      fontSize: 14,
      lineHeight: "20px",
      boxSizing: "border-box",
      whiteSpace: "pre-wrap",
      wordWrap: "break-word",
    },
    input: {
      padding: 9,
      border: "none",
      outline: "none",
      fontFamily: "inherit",
      fontSize: 14,
      lineHeight: "20px",
      boxSizing: "border-box",
      whiteSpace: "pre-wrap",
      wordWrap: "break-word",
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: "100%",
    highlighter: {
      padding: 8,
      border: "1px solid transparent",
      fontFamily: "inherit",
      fontSize: 14,
      lineHeight: "20px",
      boxSizing: "border-box",
    },
    input: {
      padding: 8,
      border: "none",
      outline: "none",
      fontFamily: "inherit",
      fontSize: 14,
      lineHeight: "20px",
      boxSizing: "border-box",
    },
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid #D1D5DB",
      borderRadius: "8px",
      fontSize: 14,
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    },
    item: {
      color: "#374151",
      borderBottom: "none",
      minHeight: "28px",
      display: "flex",
      alignItems: "center",
      "&focused": {
        backgroundColor: "#EFF6FF",
        outline: "none",
        border: "none",
      },
    },
  },

  highlighter: {
    overflow: "hidden",
  },
  input: {
    overflow: "hidden",
  },
};

export const APIBodyInput: React.FC<{
  teamSecrets: string[];
  userSecrets: string[];
  variables: string[];
  outputs: Node[];
  body: string;
  updated: (text: string) => void;
  currentNodeId?: string;
}> = ({
  teamSecrets,
  userSecrets,
  variables,
  body,
  updated,
  outputs,
  currentNodeId,
}) => {
  const { getNodes, getEdges } = useReactFlow();

  const mentionsInputRef = useRef<HTMLTextAreaElement>(null);
  const cursorPositionRef = useRef<number | null>(null);
  const [isValidJson, setIsValidJson] = React.useState(true);

  const availableConversationalData = currentNodeId
    ? findAvailableConversationalData(currentNodeId, getNodes(), getEdges())
    : [];
  const conversationalSuggestions = availableConversationalData.flatMap(
    (context) =>
      context.requirements.map((req) => ({
        id: `conversationalData.${req.id}`,
        display: `conversation.${req.name}`,
        description: req.description,
        actionName: context.actionId || "Unknown Action",
      }))
  );

  const validateJson = useCallback((text: string) => {
    if (!text) {
      setIsValidJson(true);
      return;
    }

    try {
      JSON.parse(text);
      setIsValidJson(true);
    } catch {
      setIsValidJson(false);
    }
  }, []);

  const handleTextChange = useCallback(
    (_: { target: { value: string } }, newValue: string) => {
      const inputElement = mentionsInputRef.current;
      if (inputElement) {
        cursorPositionRef.current = inputElement.selectionStart;
      }

      validateJson(newValue);
      updated(newValue);
    },
    [updated, validateJson]
  );

  useEffect(() => {
    if (mentionsInputRef.current && cursorPositionRef.current !== null) {
      mentionsInputRef.current.setSelectionRange(
        cursorPositionRef.current,
        cursorPositionRef.current
      );
    }
  });

  const handleMentionAdd = useCallback((id: string | number) => {
    if (cursorPositionRef.current !== null) {
      const idLength = id.toString().length;
      cursorPositionRef.current += idLength + 2;
    }
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <div
        className={`border-gray-300 bg-gray-50 rounded-lg border ${
          !isValidJson ? "border-red-500" : ""
        }`}
      >
        <MentionsInput
          id="bodyInput"
          style={APIBodyInputStyle}
          value={body}
          onChange={handleTextChange}
          placeholder="Enter request body. Use @ to access available values"
          inputRef={mentionsInputRef}
          className="min-h-32 font-mono"
          spellCheck={false}
        >
          <Mention
            trigger="@"
            appendSpaceOnAdd={true}
            data={userSecrets.map((secret) => ({
              id: `userSecrets.${secret}`,
              display: `userSecrets.${secret}`,
            }))}
            onAdd={handleMentionAdd}
            renderSuggestion={(data) => <UserSecretIcon title={data.display} />}
          />
          <Mention
            trigger="@"
            appendSpaceOnAdd={true}
            data={teamSecrets.map((secret) => ({
              id: `teamSecrets.${secret}`,
              display: `teamSecrets.${secret}`,
            }))}
            onAdd={handleMentionAdd}
            renderSuggestion={(data) => <TeamSecretIcon title={data.display} />}
          />
          <Mention
            trigger="@"
            appendSpaceOnAdd={true}
            data={variables.map((variable) => ({
              id: `variables.${variable}`,
              display: `variables.${variable}`,
            }))}
            onAdd={handleMentionAdd}
            renderSuggestion={(data) => <VariableIcon title={data.display} />}
          />
          <Mention
            trigger="@"
            appendSpaceOnAdd={true}
            data={outputs.map((output) => ({
              id: `outputs.${output.data.id}`,
              display: `outputs.${output.data.title}`,
            }))}
            onAdd={handleMentionAdd}
            renderSuggestion={(data) => <OutputIcon title={data.display} />}
          />
          <Mention
            trigger="@"
            appendSpaceOnAdd={true}
            data={conversationalSuggestions}
            onAdd={handleMentionAdd}
            renderSuggestion={(data) => (
              <ConversationalIcon title={data.display} />
            )}
          />
        </MentionsInput>
      </div>
    </div>
  );
};
