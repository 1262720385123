import Icon from "../../../Icon";

export const TeamSecretIcon: React.FC<{ title?: string }> = ({ title }) => {
  return (
    <div className="flex items-center gap-2 px-4 py-2">
      <Icon type="lock" className="size-3 text-blue-500" />
      <span>{title}</span>
    </div>
  );
};

export const UserSecretIcon: React.FC<{ title?: string }> = ({ title }) => {
  return (
    <div className="flex items-center gap-2 px-4 py-2">
      <Icon type="user-settings" className="size-3 text-indigo-500" />
      <span>{title}</span>
    </div>
  );
};

export const OutputIcon: React.FC<{ title?: string }> = ({ title }) => {
  return (
    <div className="flex items-center gap-2 px-4 py-2">
      <Icon type="arrow-left" className="size-3 text-gray-500 -rotate-90 " />
      <span>{title}</span>
    </div>
  );
};

export const VariableIcon: React.FC<{ title?: string }> = ({ title }) => {
  return (
    <div className="flex items-center gap-2 px-4 py-2">
      <Icon type="users" className="size-3 text-sky-500" />
      <span>{title}</span>
    </div>
  );
};

export const ConversationalIcon: React.FC<{ title?: string }> = ({ title }) => {
  return (
    <div className="flex items-center gap-2 px-4 py-2">
      <Icon type="messages" className="size-3 text-lime-500" />
      <span>{title}</span>
    </div>
  );
};
