import React from "react";
import { Handle, Position, Connection } from "reactflow";
import Icon from "../../../Icon";

export interface AddNodeHandlerParams {
  type: "source" | "target";
  isConnected: boolean;
  id: string;
  onClick?: () => void;
  alwaysShowAdd?: boolean;
  position?: Position;
}

export const AddNodeHandler: React.FC<AddNodeHandlerParams> = ({
  type,
  isConnected,
  id,
  onClick,
  alwaysShowAdd,
  position,
}) => {
  const isSource = type === "source";

  const isValidConnection = (connection: Connection) => {
    if (connection.source === connection.target) {
      return false; // Prevent self-connections
    }

    return !isConnected; // Allow connection only if there are no existing connections
  };

  return (
    <Handle
      itemID={id}
      onClick={onClick}
      type={type}
      position={position ?? isSource ? Position.Bottom : Position.Top}
      id={id}
      style={{
        background: "transparent",
        zIndex: 999,
        border: "none",
        width: "24px",
        height: "24px",
        marginTop: `${isSource ? "" : "-4px"}`,
        marginBottom: `${isSource ? "-10px" : ""}`,
      }}
      isValidConnection={isValidConnection}
      className={`testId-${id}`}
    >
      {!isConnected && (
        <Icon
          type="plus-inverted"
          className={`${
            alwaysShowAdd ? "opacity-100" : "opacity-0"
          } size-5 group-hover:opacity-100 pointer-events-none bg-white rounded-full text-blue-500`}
        />
      )}
    </Handle>
  );
};
