import { useReactFlow } from "reactflow";
import { WorkflowVersion } from "../../../../models/Workflow";
import { PortalNodeData } from "../../Map/Nodes/PortalNode";
import VariableInputSelector from "../VariableInputSelector";

export const SidebarPortalInputs: React.FC<{
  version: WorkflowVersion;
  data: PortalNodeData;
}> = ({ version, data }) => {
  const { setNodes } = useReactFlow();

  const updateNode = (newData: Partial<PortalNodeData>) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === data.id) {
          return { ...node, data: { ...node.data, ...newData } };
        }
        return node;
      })
    );
  };

  const inputs = Object.keys(data.selectedInputs ?? {});

  return (
    <div className="flex flex-col gap-2">
      {inputs.length == 0 && (
        <div className="font-gooper text-xl text-gray-700">
          No inputs for this version
        </div>
      )}
      {inputs.map((v) => {
        return (
          <div key={`${v}-col`} className="flex flex-col">
            <div className="text-gray-700 text-sm font-medium">{`input for ${v}`}</div>
            <VariableInputSelector
              id={v}
              key={v}
              availableVariables={Object.values(
                version.usedVariables ?? {}
              ).map((uv) => uv.id)}
              selectedInput={
                (data.selectedInputs ?? {})[v] ?? { type: "variable" }
              }
              setSelectedInput={(input) => {
                updateNode({
                  selectedInputs: {
                    ...(data.selectedInputs ?? {}),
                    [v]: input,
                  },
                });
              }}
              currentNodeId={data.id}
            />
          </div>
        );
      })}
    </div>
  );
};
