import { Workflow, WorkflowVersion } from "../../models/Workflow";
import Icon, { IconType } from "../Icon";

export const WorkflowStatusBadge: React.FC<{
  workflow: Workflow;
  version?: WorkflowVersion;
}> = ({ workflow, version }) => {
  let className = "";
  let text = "";
  let icon: IconType = "star";

  if (
    version?.id == workflow.currentVersionId &&
    Object.keys(version?.steps ?? {}).length > 0
  ) {
    className =
      "bg-green-50 text-green-600 border-0 stroke-green-600 fill-gray-0";
    text = "Live";
    icon = "workflows";
  } else {
    className =
      "bg-bink-50 text-pink-500 border fill-pink-500 border-pink-500 stroke-pink-500";
    text = "Draft";
    icon = "draft";
  }

  return (
    <div
      className={`self-stretch p-1 rounded border ${className} justify-center items-center gap-1 flex`}
    >
      <Icon type={icon} className="size-3" />
      <div className="text-center  text-xs font-medium leading-none">
        {text}
      </div>
    </div>
  );
};
