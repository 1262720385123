import { useReactFlow } from "reactflow";
import { Team } from "../../../../models/Team";
import { WorkflowVersion } from "../../../../models/Workflow";
import { formatDate, formatTime } from "../../../../utils/DateUtils";
import { StartNodeData, StartNodeType } from "../../Map/Nodes/StartNode";
import { VariableInput } from ".././VariableInput";
import { UserSecretsInput } from "./UserSecretsInput";
import { DemoSecretsInput } from "./DemoSecretsInputs";
import { ControlledInput } from "../../../Common/ControlledInput";

export const SidebarWorkflowMetaDetail: React.FC<{
  version: WorkflowVersion;
  setTeam: (team: Team) => void;
  team: Team;
  data: StartNodeData;
  setVersion: (workflow: WorkflowVersion) => void;
}> = ({ team, setTeam, data, version, setVersion }) => {
  const { setNodes } = useReactFlow();

  const updateNode = (newData: Partial<StartNodeData>) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === StartNodeType) {
          return { ...node, data: { ...node.data, ...newData } };
        }
        return node;
      })
    );
  };

  const addVariable = (variable: string) => {
    const usedVariables = {
      ...data.usedVariables,
      [variable]: { id: variable },
    };
    const demoVariables = data.demoVariables ?? {};
    demoVariables[variable] = "";
    const updatedWorkflow = { ...version, usedVariables };
    setVersion(updatedWorkflow);
    updateNode({
      usedVariables,
      demoVariables,
    });
  };

  const deleteVariable = (variable: string) => {
    const usedVariables = data.usedVariables ?? {};
    delete usedVariables[variable];
    const demoVariables = data.demoVariables ?? {};
    delete demoVariables[variable];
    setVersion({ ...version, usedVariables });
    updateNode({
      usedVariables,
      demoVariables,
    });
  };

  const updateName = (name: string) => {
    updateNode({ title: name });
    setVersion({ ...version, name });
  };

  const addUserSecret = (secret: string) => {
    const userSecrets = {
      ...(data.userSecrets ?? {}),
      [secret]: true,
    };
    const updatedWorkflow = {
      ...version,
      userSecrets: {
        ...(version.userSecrets ?? {}),
        [secret]: true,
      },
    };
    setVersion(updatedWorkflow);
    updateNode({ userSecrets });
  };

  const removeUserSecret = (secret: string) => {
    const userSecrets = { ...(data.userSecrets ?? {}) };
    delete userSecrets[secret];
    const versionUserSecrets = { ...(version.userSecrets ?? {}) };
    delete versionUserSecrets[secret];
    setVersion({ ...version, userSecrets: versionUserSecrets });
    updateNode({ userSecrets });
  };

  const updateDemoSecrets = (demoUserSecrets: { [key: string]: string }) => {
    setVersion({ ...version, demoUserSecrets });
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row justify-between">
        <div className="text-sm text-gray-400">{`Saved: ${formatDate(
          version.modifiedAt
        )} ${formatTime(version.modifiedAt)}`}</div>
      </div>
      <ControlledInput
        title="Agent Version Name"
        onUpdate={updateName}
        defaultValue={data.title ?? ""}
        placeholder="Agent Version Name"
        error={undefined}
        setError={undefined}
        id="sidebarVersionName"
        className="font-sans text-gray-700"
      />
      <VariableInput
        usedVariables={data.usedVariables}
        addVariable={addVariable}
        removeVariable={deleteVariable}
        teamVariables={team.variables ?? {}}
        addTeamVariable={(v) => {
          const newTeam = {
            ...team,
            variables: { ...team.variables, [v]: { id: v } },
          };
          setTeam(newTeam);
          const usedVariables = {
            ...data.usedVariables,
            [v]: { id: v },
          };
          const updatedWorkflow = { ...version, usedVariables };
          setVersion(updatedWorkflow);
          updateNode({
            usedVariables,
            demoVariables: { ...data.demoVariables, [v]: "" },
          });
        }}
      />
      <UserSecretsInput
        userSecrets={data.userSecrets}
        addUserSecret={addUserSecret}
        removeUserSecret={removeUserSecret}
      />
      <DemoSecretsInput
        userSecrets={data.userSecrets}
        demoSecrets={data.demoUserSecrets ?? {}}
        onUpdateDemoSecrets={updateDemoSecrets}
      />
    </div>
  );
};
