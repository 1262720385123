import React, { useState, useEffect } from "react";
import { Portal } from "../../../../models/Portal";
import { PortalVersion } from "../../../../models/PortalVersion";
import { CommonModal } from "../../../Common/CommonModal";
import { usePortalService } from "../../../../contexts/PortalContext";
import AnimatedButton from "../../../AnimatedButton";
interface PortalSelectionModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  teamId: string;
  onSelect: (portal: Portal, version: PortalVersion) => void;
}

export const PortalSelectionModal: React.FC<PortalSelectionModalProps> = ({
  isOpen,
  onDismiss,
  teamId,
  onSelect,
}) => {
  const [portals, setPortals] = useState<Portal[]>([]);
  const [selectedPortal, setSelectedPortal] = useState<Portal | null>(null);
  const [versions, setVersions] = useState<PortalVersion[]>([]);
  const [selectedVersion, setSelectedVersion] = useState<PortalVersion | null>(
    null
  );
  const [_, setLoading] = useState(false);
  const portalService = usePortalService();

  useEffect(() => {
    const loadPortals = async () => {
      if (!isOpen) return;
      setLoading(true);
      try {
        const fetchedPortals = await portalService.portalRepo.getList(
          portalService.portalPath(teamId),
          { name: "modifiedAt", descending: true },
          undefined
        );
        setPortals(fetchedPortals);
      } catch (error) {
        console.error("Error loading portals:", error);
      }
      setLoading(false);
    };
    loadPortals();
  }, [isOpen, teamId, portalService]);

  useEffect(() => {
    const loadVersions = async () => {
      if (!selectedPortal) return;
      setLoading(true);
      try {
        const fetchedVersions = await portalService.portalVersionRepo.getList(
          portalService.portalVersionPath(teamId, selectedPortal.id),
          { name: "modifiedAt", descending: true },
          undefined
        );
        setVersions(fetchedVersions);
      } catch (error) {
        console.error("Error loading versions:", error);
      }
      setLoading(false);
    };
    loadVersions();
  }, [selectedPortal, teamId, portalService]);

  const handleConfirm = () => {
    if (selectedPortal && selectedVersion) {
      onSelect(selectedPortal, selectedVersion);
      onDismiss();
    }
  };

  return (
    <CommonModal isOpen={isOpen} onDismiss={onDismiss}>
      <div className="w-96 p-6">
        <h2 className="text-xl font-semibold font-gooper text-gray-700 pb-1">
          Select Prompt & Version
        </h2>
        <div className="border-t pt-4 border-gray-200 -ml-6 -mr-6"></div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Prompt
          </label>
          <select
            className="w-full p-2 border border-gray-300 rounded-md"
            value={selectedPortal?.id || ""}
            onChange={(e) => {
              const portal = portals.find((p) => p.id === e.target.value);
              setSelectedPortal(portal || null);
              setSelectedVersion(null);
            }}
          >
            <option value="">Select a prompt</option>
            {portals.map((portal) => (
              <option key={portal.id} value={portal.id}>
                {portal.name}
              </option>
            ))}
          </select>
        </div>

        {selectedPortal && (
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Version
            </label>
            <select
              className="w-full p-2 border border-gray-300 rounded-md"
              value={selectedVersion?.id || ""}
              onChange={(e) => {
                const version = versions.find((v) => v.id === e.target.value);
                setSelectedVersion(version || null);
              }}
            >
              <option value="">Select a version</option>
              {versions.map((version) => (
                <option key={version.id} value={version.id}>
                  Version {version.name}
                </option>
              ))}
            </select>
          </div>
        )}

        <div className="grid grid-cols-2 justify-end gap-3">
          <AnimatedButton
            title={`Cancel`}
            onClick={() => onDismiss()}
            buttonState={"ready"}
            style={"normal"}
            key="cancel"
            id="cancelButton"
          />
          <AnimatedButton
            title={`Confirm`}
            onClick={() => handleConfirm()}
            buttonState={"ready"}
            style={"action"}
            key="confirm"
            id="confirmButton"
          />
        </div>
      </div>
    </CommonModal>
  );
};
