import React, { useState, useEffect } from "react";
import { Workflow } from "../../models/Workflow";
import { useWorkflowService } from "../../contexts/WorkflowContext";

interface DemoDataSectionProps {
  teamId: string;
}

export const WorkflowExampleSection: React.FC<DemoDataSectionProps> = ({
  teamId,
}) => {
  const workflowService = useWorkflowService();

  const [workflows, setWorkflows] = useState<Workflow[]>([]);
  const [exampleWorkflows, setExampleWorkflows] = useState<Workflow[]>([]);

  useEffect(() => {
    const loadData = async () => {
      const [fetchedWorkflows, fetchedExampleWorkflows] = await Promise.all([
        workflowService.workflowRepo.getList(
          workflowService.workflowPath(teamId),
          {
            name: "modifiedAt",
            descending: true,
          }
        ),
        workflowService.workflowRepo.getList(
          workflowService.exampleWorkflowPath(),
          {
            name: "modifiedAt",
            descending: true,
          }
        ),
      ]);
      setWorkflows(fetchedWorkflows);
      setExampleWorkflows(fetchedExampleWorkflows);
    };
    loadData();
  }, [workflowService, teamId]);

  const handleRowClick = (workflow: Workflow) => {
    console.log("clicked", workflow.id);
  };

  const handleCopyToExamples = async (workflow: Workflow) => {
    try {
      await workflowService.copyWorkflowToExamples(teamId, workflow.id!);
      const fetchedExampleWorkflows =
        await workflowService.workflowRepo.getList(
          workflowService.exampleWorkflowPath(),
          {
            name: "modifiedAt",
            descending: true,
          }
        );
      setExampleWorkflows(fetchedExampleWorkflows);
    } catch (error) {
      console.error("Error copying workflow to examples:", error);
    }
  };

  const handleDeleteExampleWorkflow = async (workflowId: string) => {
    try {
      await workflowService.deleteWorkflowExample(workflowId);
      setExampleWorkflows(exampleWorkflows.filter((w) => w.id !== workflowId));
    } catch (error) {
      console.error("Error deleting example workflow:", error);
    }
  };

  return (
    <>
      <h2 className="text-xl font-bold mb-4">Example Workflows</h2>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {exampleWorkflows.map((workflow) => (
            <tr
              key={workflow.id}
              className="hover:bg-gray-100 cursor-pointer"
              onClick={() => handleRowClick(workflow)}
            >
              <td className="px-6 py-4 whitespace-nowrap">{workflow.name}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                <button
                  className="text-red-600 hover:text-red-900"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteExampleWorkflow(workflow.id!);
                  }}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <h2 className="text-xl font-bold mb-4 mt-8">Workflows</h2>
      <table className="min-w-full divide-y divide-gray-200 mb-8">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {workflows.map((workflow) => (
            <tr
              key={workflow.id}
              className="hover:bg-gray-100 cursor-pointer"
              onClick={() => handleRowClick(workflow)}
            >
              <td className="px-6 py-4 whitespace-nowrap">{workflow.name}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                <button
                  className="text-blue-600 hover:text-blue-900"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyToExamples(workflow);
                  }}
                >
                  Copy to Examples
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
