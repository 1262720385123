import React, { useState } from "react";

import { CommonModal } from "../../Common/CommonModal";
import { formatDate, formatTime } from "../../../utils/DateUtils";
import Icon from "../../Icon";
import { CommonInputSegment } from "../../Common/CommonInput";
import { ExternalPath } from "../../../models/ExternalPath";
import AnimatedButton, { AnimationState } from "../../AnimatedButton";
import { useWorkflowService } from "../../../contexts/WorkflowContext";
import { Workflow } from "../../../models/Workflow";

interface WorkflowNameModalProps {
  teamId: string;
  shows: boolean;
  setShows: (shows: boolean) => void;
  workflow: Workflow;
  setWorkflow: (portal: Workflow) => void;
}

export const WorkflowNameModal: React.FC<WorkflowNameModalProps> = ({
  teamId,
  shows,
  setShows,
  workflow,
  setWorkflow,
}) => {
  const [saveWorkflowState, setSaveWorkflowState] =
    useState<AnimationState>("ready");
  const [workflowName, setWorkflowName] = useState(workflow.name);
  const workflowService = useWorkflowService();

  const handleSaveWorkflow = async () => {
    setSaveWorkflowState("loading");
    try {
      const newWorkflow: Workflow = { ...workflow, name: workflowName };
      workflowService.workflowRepo.update(
        newWorkflow,
        workflowService.workflowPath(teamId),
        workflow.id!
      );
      setWorkflow(newWorkflow);
      setSaveWorkflowState("success");
    } catch {
      setSaveWorkflowState("error");
    }
  };

  const handleDismiss = () => {
    setWorkflowName(workflow.name);
    setShows(false);
  };

  if (!shows) return null;

  return (
    <CommonModal isOpen={shows} onDismiss={handleDismiss}>
      <div className="bg-white rounded-lg gap-4 flex flex-col shadow-lg p-6 max-w-[544px]">
        <div className="flex justify-end text-gray-200 hover:text-gray-300">
          <button onClick={handleDismiss}>
            <Icon type="x" />
          </button>
        </div>
        <div className="flex justify-between items-center">
          <div className="flex justify-between text-xs text-gray-500 gap-2">
            <span> Created</span>
            <span>{formatDate(workflow.createdAt)}</span>
            <span>{formatTime(workflow.createdAt)}</span>
          </div>
          <div
            id="agentId"
            className="flex justify-between text-xs text-gray-500 gap-2"
          >
            {`Agent ID: ${workflow.id}`}
          </div>
        </div>
        <div className="text-center flex flex-col gap-1 mt-4 mb-4">
          <div className="font-gooper text-2xl font-semibold text-gray-800">
            Give your Agent a name
          </div>
          <div className="font-normal text-gray-500">
            This is your home to create complex multi-setp AI systems. Each
            Agent can be integrated into your app and power an AI feature.
          </div>
        </div>
        <CommonInputSegment
          title=""
          onChange={setWorkflowName}
          value={workflowName}
          placeholder="Name your Agent here"
          id="agentName"
        />
        <div className="flex flex-row justify-between">
          <div className="text-gray-500 text-xs">You can change this later</div>

          <a
            href={ExternalPath.agentDocs()}
            target="_blank"
            className="text-blue-400 hover:text-blue-500 text-xs underline"
          >
            Learn more about agents here
          </a>
        </div>
        <div className="flex flex-row justify-between">
          <AnimatedButton
            title={`Cancel`}
            onClick={handleDismiss}
            buttonState={"ready"}
            style={"normal"}
            key="cancel"
            font="font-sans"
            id="cancelButton"
            classNameIn="w-40"
          />
          <AnimatedButton
            title={"Save Agent"}
            onClick={() => handleSaveWorkflow()}
            buttonState={saveWorkflowState}
            setButtonState={setSaveWorkflowState}
            style={"action"}
            key="action"
            leftIcon={"workflows"}
            font="font-sans"
            id="saveButton"
            classNameIn="w-40"
            after={(success) => {
              if (success) {
                handleDismiss();
              }
            }}
            triggerKey="Enter"
          />
        </div>
      </div>
    </CommonModal>
  );
};
