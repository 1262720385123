import { useNavigate } from "react-router-dom";
import { ChatConfigurationTemplate } from "../../../models/ChatConfigurationTemplate";
import { Portal } from "../../../models/Portal";
import { PortalVersion } from "../../../models/PortalVersion";
import { CommonCard } from "../../Common/CommonCard";
import { CommonInputSegment } from "../../Common/CommonInput";
import { StatusBadge } from "../StatusBadge";
import { AIProviderList } from "./AIProvider/AIProviderList";
import { PromptInput } from "./PromptInput/PromptInput";
import { ReactNode, useState } from "react";
import { extractDefaultConfig } from "../../../utils/ExtractDefaultChatConfig";
import { ScoreBadge } from "../ScoreBadge";
import { formatDate, formatTime } from "../../../utils/DateUtils";
import { CommonUnderlineButton } from "../../Common/CommonUnderlineButton";
import { AIProviderConfigModal } from "./AIProvider/AIProviderConfigModal";
import { AppPath } from "../../../models/AppPath";
import { Team } from "../../../models/Team";

export const VersionDetailCard: React.FC<{
  portal?: Portal;
  version: PortalVersion;
  updatedVersion: (version: PortalVersion) => void;
  updatePrompt: (prompt: string) => void;
  variables: string[];
  templates: ChatConfigurationTemplate[];
  setIsLoading?: (isLoading: boolean) => void;
  nameError?: string;
  setNameError?: (error: string) => void;
  addedVariable: (newVariable: string) => void;
  teamId: string;
  hideCard?: boolean;
  team: Team;
}> = ({
  portal,
  version,
  updatedVersion,
  variables,
  templates,
  setIsLoading,
  nameError,
  setNameError,
  addedVariable,
  teamId,
  updatePrompt,
  hideCard,
  team,
}) => {
  const navigate = useNavigate();

  const [showConfig, setShowConfig] = useState(false);

  const handleSelectedProvider = (providerId: string) => {
    const template = templates!.filter((t) => t.id == providerId)[0];
    const defaultConfig = extractDefaultConfig(template);
    updatedVersion({
      ...version!,
      configId: providerId,
      config: defaultConfig,
    });
  };

  const isLive =
    portal?.currentVersionId == version.id &&
    portal?.currentVersionId != undefined;

  const currentTemplate = templates?.filter(
    (t) => t.id == version?.configId
  )[0];

  const handleNavigateToCurrentVersion = () => {
    if (!portal) {
      return;
    }
    setIsLoading?.(true);
    navigate(
      AppPath.portalVersion(teamId, portal?.id!, portal.currentVersionId!)
    );
  };

  const rightItem = (): ReactNode => {
    return (
      <>
        {portal && (
          <div className="flex flex-row items-center justify-between gap-2">
            <div className="flex flex-row items-center justify-between gap-2">
              {!isLive && portal?.currentVersionId && (
                <CommonUnderlineButton
                  title="Go to live version"
                  onClick={handleNavigateToCurrentVersion}
                />
              )}
              {version.averageReviewScore != null && portal && (
                <ScoreBadge
                  score={version.averageReviewScore}
                  portalId={portal.id!}
                  versionId={version.id!}
                  teamId={teamId}
                />
              )}
              {portal && <StatusBadge portal={portal} version={version} />}
            </div>
          </div>
        )}
      </>
    );
  };

  const content = (): ReactNode => {
    return (
      <div className={`flex flex-col gap-6`}>
        {portal && (
          <CommonInputSegment
            title="Version Name"
            onChange={(name) => updatedVersion({ ...version, name: name })}
            value={version.name ?? ""}
            placeholder="Version Name"
            error={nameError}
            setError={setNameError}
            id="versionName"
            rightItem={rightItem()}
            className="font-sans text-gray-700"
            leftItem={
              <div className="text-xs text-gray-400">{`Version No ${version.number}`}</div>
            }
          />
        )}

        <AIProviderConfigModal
          version={version}
          setVersion={updatedVersion}
          template={currentTemplate}
          setShows={setShowConfig}
          shows={showConfig}
          teamId={teamId}
        />

        <AIProviderList
          clickedProvider={handleSelectedProvider}
          selectedProvider={version.configId ?? ""}
          providers={templates ?? []}
          showProviderConfig={setShowConfig}
          configuredProviders={Object.keys(team.secretsUsed ?? {})}
        />
        <PromptInput
          updated={updatePrompt}
          prompt={version.prompt ?? ""}
          variables={variables}
          addedVariable={addedVariable}
          teamId={teamId}
        />
        <div className="flex flex-row justify-between">
          <div className="flex flex-row text-xs text-gray-400 gap-1 justify-end">
            <span>Last saved</span>
            <span>{formatDate(version.modifiedAt)}</span>
            <span>{formatTime(version.modifiedAt)}</span>
          </div>
          {portal?.currentVersionData && (
            <div className="flex flex-row text-xs text-gray-400 gap-1 justify-end">
              <span> Published</span>
              <span>{formatDate(portal?.publishedAt)}</span>
              <span>{formatTime(portal?.publishedAt)}</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  return hideCard ? (
    content()
  ) : (
    <CommonCard className={`${isLive ? "border-green-200 border-2" : ""}`}>
      {content()}{" "}
    </CommonCard>
  );
};
