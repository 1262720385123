import { useReactFlow } from "reactflow";
import { Team } from "../../../../models/Team";
import { WorkflowVersion } from "../../../../models/Workflow";
import { ActionNodeData } from "../../Map/Nodes/ActionNode";
import { SidebarActionDetail } from "./SidebarActionDetail";
import { ChatConfigurationTemplate } from "../../../../models/ChatConfigurationTemplate";

export const SidebarActionContainer: React.FC<{
  team: Team;
  data: ActionNodeData;
  version: WorkflowVersion;
  templates: ChatConfigurationTemplate[];
}> = ({ team, data, version, templates }) => {
  const { setNodes } = useReactFlow();

  const updateNode = (newData: Partial<ActionNodeData>) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === data.id) {
          return { ...node, data: { ...node.data, ...newData } };
        }
        return node;
      })
    );
  };

  return (
    <div className="flex flex-col gap-2 -mt-2">
      <SidebarActionDetail
        data={data}
        updateNode={updateNode}
        team={team}
        version={version}
        templates={templates}
      />
    </div>
  );
};
