import React from "react";
import { ChatConfigurationTemplate } from "../../../../models/ChatConfigurationTemplate";
import { AIProviderLogo } from "./AiProviderLogo";
import { CommonUnderlineButton } from "../../../Common/CommonUnderlineButton";
import Icon from "../../../Icon";

export const AIProviderList: React.FC<{
  clickedProvider: (id: string) => void;
  selectedProvider: string;
  providers: ChatConfigurationTemplate[];
  showProviderConfig: (show: boolean) => void;
  configuredProviders: string[];
  hideConfigure?: boolean;
  classNameIn?: string;
}> = ({
  clickedProvider,
  selectedProvider,
  providers,
  showProviderConfig,
  configuredProviders,
  hideConfigure,
  classNameIn,
}) => {
  return (
    <div className="flex flex-col gap-2 justify-normal w-full ">
      <div className="flex flex-row justify-between items-center">
        <div
          className={`font-sans text-sm text-gray-700 font-medium ${classNameIn}`}
        >
          Provider Configuration
        </div>
        {hideConfigure != true && (
          <CommonUnderlineButton
            title="Open Provider Settings"
            onClick={() => showProviderConfig(true)}
          />
        )}
      </div>

      <div className={`flex flex-wrap items-center gap-2 justify-start w-full`}>
        {providers.map((provider) => {
          const configured = configuredProviders.includes(provider.id);
          return (
            <button
              id={provider.id}
              key={provider.id}
              className={`relative bg-gray-100 hover:bg-gray-200  flex items-center justify-center stroke-gray-0 fill-gray-0 h-8 w-20 p-2 text-xs font-medium leading-none rounded-md ${
                configured ? "" : ""
              }`}
              onClick={() => clickedProvider(provider.id)}
            >
              <AIProviderLogo
                providerId={provider.id}
                className={`p-[1px] ${
                  selectedProvider == provider.id
                    ? "stroke-blue-500 fill-blue-500"
                    : "fill-gray-400"
                }`}
              />
              <Icon
                type="check-line"
                className={`${
                  configured ? "visible" : "invisible"
                } text-green-500 absolute bottom-0 right-0 size-3`}
              />
            </button>
          );
        })}
      </div>
    </div>
  );
};
