import { CommonInputSegment } from "../../../Common/CommonInput";
import { useReactFlow } from "reactflow";
import { WebsiteNodeData } from "../../Map/Nodes/WebsiteNode";
import Icon from "../../../Icon";
import { Team } from "../../../../models/Team";
import { WorkflowVersion } from "../../../../models/Workflow";
import { findAvailableOutputNodes } from "../../Map/Nodes/NodeUtils";
import { APISingleLineInput } from "../API/APISingleLineInput";
import AnimatedButton from "../../../AnimatedButton";
import { useState } from "react";
import { WebsiteTestModal } from "./WebsiteTestModal";

export const SidebarWebsiteDetail: React.FC<{
  data: WebsiteNodeData;
  team: Team;
  version: WorkflowVersion;
}> = ({ data, team, version }) => {
  const { setNodes, getNodes, getEdges } = useReactFlow();

  const [showTestModal, setShowTestModal] = useState(false);

  const teamSecrets = Object.keys(team.customSecretsUsed ?? {});
  const userSecrets = Object.keys(version.userSecrets ?? {});
  const variables = Object.keys(version.usedVariables ?? {});
  const nodes = getNodes();
  const edges = getEdges();
  const outputs = findAvailableOutputNodes(data.id, nodes, edges);

  const updateNode = (newData: Partial<WebsiteNodeData>) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === data.id) {
          return { ...node, data: { ...node.data, ...newData } };
        }
        return node;
      })
    );
  };

  const updateFilterOption = (
    key: keyof WebsiteNodeData["filterOptions"],
    value: boolean
  ) => {
    updateNode({
      filterOptions: {
        ...data.filterOptions,
        [key]: value,
      },
    });
  };

  const addCustomSelector = (selector: string) => {
    if (!selector.trim()) return;
    updateNode({
      filterOptions: {
        ...data.filterOptions,
        customSelectors: [
          ...data.filterOptions.customSelectors,
          selector.trim(),
        ],
      },
    });
  };

  const removeCustomSelector = (index: number) => {
    updateNode({
      filterOptions: {
        ...data.filterOptions,
        customSelectors: data.filterOptions.customSelectors.filter(
          (_, i) => i !== index
        ),
      },
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <WebsiteTestModal
        shows={showTestModal}
        setShows={setShowTestModal}
        data={data}
        team={team}
      />
      <AnimatedButton
        title="Preview Website"
        buttonState={"ready"}
        setButtonState={() => console.log("")}
        onClick={() => setShowTestModal(true)}
        style="secondary"
        disabled={!data.url}
        font="font-sans font-medium"
        leftIcon="play"
      />

      <CommonInputSegment
        title="Website Step Title"
        onChange={(t) => updateNode({ title: t })}
        value={data.title}
        placeholder="Step Title"
        error={undefined}
        setError={undefined}
        id="beamTitle"
        className="font-sans text-gray-700"
      />

      <div className="flex flex-col gap-2">
        <div className="text-sm font-medium text-gray-700">URL</div>
        <APISingleLineInput
          teamSecrets={teamSecrets}
          userSecrets={userSecrets}
          variables={variables}
          value={data.url ?? ""}
          updated={(url) => updateNode({ url })}
          outputs={outputs}
          placeholder="https://www.example.com"
          currentNodeId={data.id}
        />
      </div>

      <div className="flex flex-col gap-2">
        <div className="text-sm font-medium text-gray-700">Content Filters</div>
        <div className="flex flex-col gap-2 p-3 bg-gray-50 rounded-md">
          <FilterOption
            label="Remove Scripts"
            checked={data.filterOptions.removeScripts}
            onChange={(e) =>
              updateFilterOption("removeScripts", e.target.checked)
            }
          />
          <FilterOption
            label="Remove Styles"
            checked={data.filterOptions.removeStyles}
            onChange={(e) =>
              updateFilterOption("removeStyles", e.target.checked)
            }
          />
          <FilterOption
            label="Remove Navigation"
            checked={data.filterOptions.removeNavigation}
            onChange={(e) =>
              updateFilterOption("removeNavigation", e.target.checked)
            }
          />
          <FilterOption
            label="Remove Footer"
            checked={data.filterOptions.removeFooter}
            onChange={(e) =>
              updateFilterOption("removeFooter", e.target.checked)
            }
          />
          <FilterOption
            label="Remove Ads"
            checked={data.filterOptions.removeAds}
            onChange={(e) => updateFilterOption("removeAds", e.target.checked)}
          />
          <FilterOption
            label="Keep Main Content Only"
            checked={data.filterOptions.keepMainContent}
            onChange={(e) =>
              updateFilterOption("keepMainContent", e.target.checked)
            }
          />
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <div className="flex flex-row justify-between items-center">
          <div className="text-sm font-medium text-gray-700">
            Custom Selectors
          </div>
          <button
            onClick={() => addCustomSelector("custom")}
            className="p-2 text-blue-500 hover:bg-blue-50 rounded-md"
          >
            <Icon type="plus" className="size-6" />
          </button>
        </div>
        <div className="flex flex-col gap-2">
          {data.filterOptions.customSelectors.map((selector, index) => (
            <div key={index} className="flex flex-row gap-2">
              <CommonInputSegment
                title={selector}
                value={selector}
                onChange={(newSelector) => {
                  const newSelectors = [...data.filterOptions.customSelectors];
                  newSelectors[index] = newSelector;
                  updateNode({
                    filterOptions: {
                      ...data.filterOptions,
                      customSelectors: newSelectors,
                    },
                  });
                }}
                placeholder="Enter CSS selector (e.g., .article, #main)"
                error={undefined}
                setError={undefined}
                id={`selector-${index}`}
                className="flex-1 font-sans text-gray-700"
              />
              <button
                onClick={() => removeCustomSelector(index)}
                className="p-2 text-red-200 hover:bg-red-50 rounded-md items-center justify-center"
              >
                <Icon type="x-no-circle" />
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

interface FilterOptionProps {
  label: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FilterOption: React.FC<FilterOptionProps> = ({
  label,
  checked,
  onChange,
}) => {
  return (
    <label className="flex items-center gap-2 cursor-pointer">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="rounded border-gray-300 text-blue-500 focus:ring-blue-500"
      />
      <span className="text-sm text-gray-600">{label}</span>
    </label>
  );
};
