import { useState } from "react";
import { useReactFlow } from "reactflow";
import { Team } from "../../../../models/Team";
import { APINodeData } from "../../Map/Nodes/APINode";
import { SidebarAPIDetail } from "./SidebarAPIDetail";
import { APITestModal } from "./APITestModal";
import AnimatedButton, { AnimationState } from "../../../AnimatedButton";
import { WorkflowVersion } from "../../../../models/Workflow";

export const SidebarAPIContainer: React.FC<{
  team: Team;
  data: APINodeData;
  version: WorkflowVersion;
}> = ({ team, data, version }) => {
  const { setNodes } = useReactFlow();
  const [testState, setTestState] = useState<AnimationState>("ready");
  const [showTestModal, setShowTestModal] = useState(false);

  const updateNode = (newData: Partial<APINodeData>) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === data.id) {
          return { ...node, data: { ...node.data, ...newData } };
        }
        return node;
      })
    );
  };

  return (
    <div className="flex flex-col gap-2 -mt-2">
      <APITestModal
        shows={showTestModal}
        setShows={setShowTestModal}
        data={data}
        team={team}
      />
      <AnimatedButton
        title="Preview API"
        buttonState={testState}
        setButtonState={setTestState}
        onClick={() => setShowTestModal(true)}
        style="secondary"
        disabled={!data.url}
        font="font-sans font-medium"
        leftIcon="play"
      />
      <SidebarAPIDetail
        data={data}
        updateNode={updateNode}
        team={team}
        version={version}
      />
    </div>
  );
};
