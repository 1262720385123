import { useState } from "react";
import { Workflow, WorkflowVersion } from "../../../models/Workflow";
import AnimatedButton, { AnimationState } from "../../AnimatedButton";
import { Team } from "../../../models/Team";
import Icon from "../../Icon";
import { CommonCard } from "../../Common/CommonCard";
import { WorkflowActionMenu } from "./WorkflowActionMenu";
import { useWorkflowConverter } from "../../../contexts/WorkflowConverterContext";
import { useWorkflowService } from "../../../contexts/WorkflowContext";
import { useStore, useReactFlow } from "reactflow";
import { v4 as uuidv4 } from "uuid";
import { useSearchParams } from "react-router-dom";
import { WorkflowConversionProblem } from "../../../services/WorkflowConverter";
import { WorkflowNameModal } from "./WorkflowNameModal";
import { WorkflowDemoModal } from "./Meta/WorkflowDemoModal";
import { StartNodeData, StartNodeType } from "../Map/Nodes/StartNode";

export const SidebarHeader: React.FC<{
  workflow: Workflow;
  workflowVersion: WorkflowVersion;
  team: Team;
  setWorkflow: (workflow: Workflow) => void;
  setWorkflowVersion: (version: WorkflowVersion) => void;
  onProblemsChange: (problems: WorkflowConversionProblem[]) => void;
}> = ({
  workflow,
  workflowVersion,
  team,
  setWorkflow,
  setWorkflowVersion,
  onProblemsChange,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [publishState, setPublishState] = useState<AnimationState>("ready");
  const [demoState, setDemoState] = useState<AnimationState>("ready");
  const [searchParams, setSearchParams] = useSearchParams();

  const converter = useWorkflowConverter();
  const workflowService = useWorkflowService();
  const { setNodes } = useReactFlow();

  // Get nodes and edges from ReactFlow
  const nodes = useStore((state) => Array.from(state.nodeInternals.values()));
  const edges = useStore((state) => Array.from(state.edges.values()));

  const updateNodes = (
    updates: { [id: string]: { data: any; general: any } },
    generalUpdates?: any
  ) => {
    const ids = Object.keys(updates);
    setNodes((nds) =>
      nds.map((node) => {
        if (ids.includes(node.id)) {
          return {
            ...node,
            data: { ...node.data, ...updates[node.id].data },
            ...updates[node.id].general,
          };
        } else {
          return { ...node, ...generalUpdates };
        }
      })
    );
  };

  const handlePublish = async () => {
    setPublishState("loading");
    const result = converter.getSteps(
      nodes,
      edges,
      team,
      false,
      workflowVersion
    );

    if (result.type === "issue") {
      const nodeData: { [id: string]: any } = {};
      result.problems.forEach((p) => {
        nodeData[p.nodeId] = { data: { hasProblem: true } };
      });
      updateNodes(nodeData);
      onProblemsChange(result.problems);
      setPublishState("error");
      return;
    }

    try {
      const newWorkflowVersion: WorkflowVersion = {
        ...workflowVersion,
        steps: result.steps,
        firstStepId: result.firstStepId,
        startNodeId: result.startNodeId,
      };
      const newWorkflow: Workflow = {
        ...workflow,
        currentVersionData: newWorkflowVersion,
        currentVersionId: newWorkflowVersion.id,
      };

      await Promise.all([
        workflowService.workflowRepo.set(
          newWorkflow,
          workflowService.workflowPath(team.id!),
          workflow.id!
        ),
        workflowService.workflowVersionRepo.set(
          newWorkflowVersion,
          workflowService.workflowVersionPath(team.id!, workflow.id!),
          workflowVersion.id!
        ),
      ]);

      setWorkflow(newWorkflow);
      setPublishState("success");
    } catch (e) {
      setPublishState("error");
    }
  };

  const handleRunDemo = async () => {
    const result = converter.getSteps(
      nodes,
      edges,
      team,
      true,
      workflowVersion
    );

    if (result.type === "conversion") {
      const newWorkflowVersion: WorkflowVersion = {
        ...workflowVersion,
        demoFirstStepId: result.firstStepId,
        demoSteps: result.steps,
        demoStartNodeId: result.startNodeId,
      };

      setDemoState("loading");
      setWorkflowVersion(newWorkflowVersion);

      await workflowService.workflowVersionRepo.update(
        newWorkflowVersion,
        workflowService.workflowVersionPath(team.id!, workflow.id!),
        workflowVersion.id!
      );

      setDemoState("success");
      setSearchParams({ sessionId: uuidv4() });
    } else {
      const nodeData: { [id: string]: any } = {};
      result.problems.forEach((p) => {
        nodeData[p.nodeId] = { data: { hasProblem: true } };
      });
      updateNodes(nodeData);
      onProblemsChange(result.problems);
      setDemoState("error");
    }
  };

  const handleSetShowNameModal = (show: boolean) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (show) {
      newSearchParams.set("showNameModal", "true");
    } else {
      newSearchParams.delete("showNameModal");
    }
    setSearchParams(newSearchParams, { replace: true });
  };

  const startNodeData = (): StartNodeData | undefined => {
    const start = nodes.find((n) => n.type === StartNodeType);
    return start?.data;
  };

  const updateStartNodeData = (data: StartNodeData) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === data.id) {
          return { ...node, data: { ...node.data, ...data } };
        }
        return node;
      })
    );
  };

  const showNameModal = searchParams.get("showNameModal") === "true";
  const sessionId = searchParams.get("sessionId");
  const data = startNodeData();

  return (
    <>
      <CommonCard padding="sm" className="w-[454px] z-10">
        <div className="flex flex-col gap-2">
          <AnimatedButton
            buttonState={publishState}
            setButtonState={setPublishState}
            title="Publish"
            onClick={handlePublish}
            style="action"
            font="font-general-sans font-medium"
            leftIcon="workflows"
            id={`publishButton`}
          />
          <div className="flex flex-row w-full gap-2 relative">
            <AnimatedButton
              title="Preview Agent"
              buttonState={demoState}
              setButtonState={setDemoState}
              onClick={handleRunDemo}
              style="secondary"
              font="font-sans font-medium"
              leftIcon="play"
              classNameIn="w-full"
            />
            <button
              id="actionMenuButton"
              className="bg-blue-50 hover:bg-blue-100 transition-all duration-200 w-12 rounded-lg items-center justify-center flex"
              onClick={() => setShowMenu(true)}
            >
              <Icon type="dots-horizontal" />
            </button>
            {workflow && workflowVersion && (
              <WorkflowActionMenu
                setShows={setShowMenu}
                shows={showMenu}
                workflow={workflow}
                version={workflowVersion}
                teamId={team.id!}
                isLive={false}
                setWorkflowVersion={setWorkflowVersion}
                setWorkflow={setWorkflow}
                setShowNameModal={handleSetShowNameModal}
              />
            )}
          </div>
        </div>
      </CommonCard>

      {/* Modals */}
      <WorkflowNameModal
        teamId={team.id!}
        workflow={workflow}
        shows={showNameModal}
        setShows={handleSetShowNameModal}
        setWorkflow={setWorkflow}
      />

      <WorkflowDemoModal
        setShows={() => setSearchParams({})}
        shows={sessionId != undefined}
        workflow={workflow}
        sessionId={sessionId ?? ""}
        setSessionId={(id) => setSearchParams(id ? { sessionId: id } : {})}
        team={team}
        variables={data?.demoVariables ?? {}}
        version={workflowVersion}
        setVariables={(demoVariables) => {
          if (data) {
            updateStartNodeData({ ...data, demoVariables });
          }
        }}
      />
    </>
  );
};
