import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export type TeamSettingsSection =
  | "variables"
  | "providers"
  | "tokens"
  | "billing"
  | "members"
  | "team-profile"
  | "custom-secrets";

interface Section {
  id: TeamSettingsSection;
  title: string;
}

export const TeamSettingsSections: React.FC<{
  selectedId: TeamSettingsSection;
}> = ({ selectedId }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const sections: Section[] = [
    { id: "tokens", title: "Access Tokens" },
    { id: "providers", title: "AI Providers" },
    { id: "billing", title: "Billing" },
    { id: "members", title: "Members" },
    { id: "team-profile", title: "Team Profile" },
    { id: "variables", title: "Variables" },
    { id: "custom-secrets", title: "Custom Secrets" },
  ];

  const handleNavigation = (sectionId: string) => {
    const newPath = location.pathname.replace(/[^/]*$/, sectionId);
    navigate(newPath);
  };

  return (
    <div className="flex flex-wrap gap-2">
      {sections.map((section) => {
        const selected = section.id == selectedId;
        return (
          <button
            id={`${section.id}Button`}
            onClick={() => handleNavigation(section.id)}
            key={section.id}
            className={`${
              selected
                ? "bg-blue-50 border-blue-200 text-blue-600"
                : "bg-transparent border-gray-200 text-gray-500"
            } rounded-3xl border px-2.5 py-0.5 text-sm font-medium`}
          >
            {section.title}
          </button>
        );
      })}
    </div>
  );
};
