import React from "react";
import { AIProviderLogo } from "./AIProvider/AiProviderLogo";
import { ChatMessage } from "../../../models/ChatMessage";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

export const ChatBubble: React.FC<{
  teamName: string;
  message: ChatMessage;
  loading: boolean;
  isLast: boolean;
  id?: string;
  hideCost?: boolean;
}> = ({ message, teamName, loading, isLast, id, hideCost }) => {
  const isAi = message.role === "AI";
  const senderName = isAi ? teamName : "User";
  const className = isAi ? "bg-gray-0 shadow" : "";

  return (
    <div
      id={id}
      className={`flex flex-col p-4 gap-2 w-full rounded-2xl ${className}`}
    >
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row gap-2 items-center">
          <div
            className={`text-xs font-gooper ${
              isAi ? "text-gray-500 font-medium" : "text-gray-600"
            }`}
          >
            {senderName}
          </div>
          {isAi && message.providerId && (
            <AIProviderLogo
              providerId={message.providerId}
              className="fill-gray-500 bg-gray-100 rounded-lg p-1 h-6 w-12"
              id={`${id}_${message.providerId}`}
            />
          )}
        </div>
        {isAi && hideCost != true && (
          <div className="text-xs text-gray-400">
            {`Cost: ${(message.cost ?? 0).toFixed(5)} USD`}
          </div>
        )}
      </div>

      <div className="relative">
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          className={`prose prose-sm max-w-3xl ${
            isAi ? "text-slate-900" : "text-gray-900"
          }`}
        >
          {message.content}
        </ReactMarkdown>

        {loading && isLast && (
          <div className="inline-block ml-1">
            <TextAnimation />
          </div>
        )}
      </div>
    </div>
  );
};

const TextAnimation: React.FC = () => {
  return (
    <svg
      width="45"
      height="10"
      viewBox="0 0 120 30"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000"
      className="inline align-baseline"
    >
      <circle cx="15" cy="15" r="15">
        <animate
          attributeName="r"
          from="15"
          to="15"
          begin="0s"
          dur="0.8s"
          values="15;9;15"
          calcMode="linear"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fillOpacity"
          from="1"
          to="1"
          begin="0s"
          dur="0.8s"
          values="1;.5;1"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="60" cy="15" r="9" fillOpacity="0.9">
        <animate
          attributeName="r"
          from="9"
          to="9"
          begin="0s"
          dur="0.8s"
          values="9;15;9"
          calcMode="linear"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fillOpacity"
          from="0.5"
          to="0.5"
          begin="0s"
          dur="0.8s"
          values=".5;1;.5"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="105" cy="15" r="15">
        <animate
          attributeName="r"
          from="15"
          to="15"
          begin="0s"
          dur="0.8s"
          values="15;9;15"
          calcMode="linear"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fillOpacity"
          from="1"
          to="1"
          begin="0s"
          dur="0.8s"
          values="1;.5;1"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  );
};

export default ChatBubble;
