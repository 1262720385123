import { Repository } from "../repos/Repository";
import { Workflow, WorkflowVersion } from "../models/Workflow";
import { FunctionRepository } from "../repos/FunctionRepository";
import { Networking } from "../utils/NetworkHelper";
import { ChatMessage } from "../models/ChatMessage";
import { DemoResponse, FilledVariables } from "./PortalService";
import { WorkflowDemoResult } from "../models/WorkflowDemoResult";
import { parseDates } from "../utils/DateUtils";
import { SoloAgent } from "../models/SoloAgent";

export interface WorkflowService {
  agentRepo: Repository<SoloAgent>;
  agentPath(): string;
  workflowRepo: Repository<Workflow>;
  workflowPath(teamId: string): string;
  workflowVersionRepo: Repository<WorkflowVersion>;
  workflowVersionPath(teamId: string, workflowId: string): string;
  workflowDemoResultRepo: Repository<WorkflowDemoResult>;
  workflowDemoResultPath(
    teamId: string,
    workflowId: string,
    versionId: string
  ): string;
  createWorkflow(teamId: string): Promise<Workflow>;
  streamDemo(
    teamId: string,
    workflowId: string,
    versionId: string,
    filledVariables: FilledVariables,
    messages: ChatMessage[],
    sessionId: string,
    onNewMessage: (demoResponse: DemoResponse) => void,
    onClose: () => void,
    onError: (error: Error) => void
  ): Promise<void>;
  deleteWorkflow(teamId: string, workflowId: string): Promise<void>;
  duplicateVersion(
    teamId: string,
    workflowId: string,
    versionId: string
  ): Promise<WorkflowVersion>;
  deleteVersion(
    teamId: string,
    workflowId: string,
    versionId: string
  ): Promise<void>;

  updateVersion(
    version: WorkflowVersion,
    versionId: string,
    workflowId: string,
    teamId: string
  ): Promise<void>;
  copyWorkflowToExamples(teamId: string, workflowId: string): Promise<void>;
  deleteWorkflowExample(workflowId: string): Promise<void>;
  exampleWorkflowPath(): string;
  createSoloAgent(
    agentToken: string,
    agentId: string,
    teamId: string
  ): Promise<{ agent: SoloAgent }>;
  updateSoloAgent(
    agent: SoloAgent,
    agentId: string,
    teamId: string
  ): Promise<SoloAgent>;
  deleteSoloAgent(teamId: string, agentId: string): Promise<void>;
  publishSoloAgent(agentId: string, teamId: string): Promise<SoloAgent>;
}

export class FirestoreWorkflowService implements WorkflowService {
  constructor(
    public agentRepo: Repository<SoloAgent>,
    public workflowRepo: Repository<Workflow>,
    public workflowVersionRepo: Repository<WorkflowVersion>,
    public workflowDemoResultRepo: Repository<WorkflowDemoResult>,
    private functionsWrapper: FunctionRepository,
    private networkHelper: Networking
  ) {}

  agentPath(): string {
    return `soloAgents`;
  }

  workflowPath(teamId: string): string {
    return `teams/${teamId}/workflows`;
  }
  workflowVersionPath(teamId: string, workflowId: string): string {
    return `teams/${teamId}/workflows/${workflowId}/versions`;
  }
  workflowDemoResultPath(
    teamId: string,
    workflowId: string,
    versionId: string
  ): string {
    return `teams/${teamId}/workflows/${workflowId}/versions/${versionId}/demoResults`;
  }

  exampleWorkflowPath(): string {
    return `exampleWorkflows`;
  }

  async createWorkflow(teamId: string): Promise<Workflow> {
    const result = await this.functionsWrapper.callFunction("createWorkflow", {
      teamId,
    });
    const resultWorkflow = result as { workflow: Workflow };
    return resultWorkflow.workflow;
  }

  async streamDemo(
    teamId: string,
    workflowId: string,
    versionId: string,
    filledVariables: FilledVariables,
    messages: ChatMessage[],
    sessionId: string,
    onNewMessage: (demoResponse: DemoResponse) => void,
    onClose: () => void,
    onError: (error: Error) => void
  ): Promise<void> {
    const params = {
      teamId,
      userData: filledVariables,
      messages,
      stream: "true",
      sessionId,
      versionId,
    };

    const handleError = (error: Error) => {
      onError(error);
    };

    let buffer = "";
    const handleMessage = (chunk: string) => {
      buffer += chunk;
      try {
        while (buffer.includes("\n\n")) {
          const end = buffer.indexOf("\n\n");
          const message = buffer.substring(0, end);
          buffer = buffer.substring(end + 2);

          if (message.startsWith("data: ")) {
            const dataJson = message.slice(6);
            const data = JSON.parse(dataJson);
            if (data.error) {
              throw new Error(
                `Stream error: ${data.error} status: ${data.status ?? 0}`
              );
            }
            const messageData = data as DemoResponse;
            onNewMessage(messageData);
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          handleError(error);
        } else {
          handleError(new Error("Unknown json processing error"));
        }
      }
    };

    const handleClose = () => {
      onClose();
    };

    await this.networkHelper.sendStream(
      `/workflow-demo/${workflowId}`,
      "POST",
      params,
      handleMessage,
      handleClose,
      handleError
    );
  }

  cancelCurrentStream() {
    this.networkHelper.cancelStream();
  }

  async deleteWorkflow(teamId: string, workflowId: string): Promise<void> {
    await this.functionsWrapper.callFunction("deleteWorkflow", {
      teamId,
      workflowId,
    });
  }

  async duplicateVersion(
    teamId: string,
    workflowId: string,
    versionId: string
  ): Promise<WorkflowVersion> {
    const result = await this.functionsWrapper.callFunction(
      "duplicateWorkflowVersion",
      { workflowId, versionId, teamId }
    );
    const formatted = parseDates(result);
    return formatted as WorkflowVersion;
  }

  async deleteVersion(
    teamId: string,
    workflowId: string,
    versionId: string
  ): Promise<void> {
    await this.functionsWrapper.callFunction("deleteWorkflowVersion", {
      teamId,
      workflowId,
      versionId,
    });
  }

  async updateVersion(
    version: WorkflowVersion,
    versionId: string,
    workflowId: string,
    teamId: string
  ): Promise<void> {
    await this.functionsWrapper.callFunction("updateWorkflowVersion", {
      version,
      teamId,
      workflowId,
      versionId,
    });
  }
  async copyWorkflowToExamples(
    teamId: string,
    workflowId: string
  ): Promise<void> {
    await this.functionsWrapper.callFunction("copyWorkflowToExamples", {
      teamId,
      workflowId,
    });
  }
  async deleteWorkflowExample(workflowId: string): Promise<void> {
    await this.functionsWrapper.callFunction("deleteWorkflowExample", {
      workflowId,
    });
  }
  async createSoloAgent(
    agentToken: string,
    agentId: string,
    teamId: string
  ): Promise<{ agent: SoloAgent }> {
    const result = await this.functionsWrapper.callFunction("createSoloAgent", {
      agentToken,
      agentId,
      teamId,
    });
    const formattedResult = parseDates(result);
    return formattedResult as { agent: SoloAgent };
  }

  async updateSoloAgent(
    agent: SoloAgent,
    agentId: string,
    teamId: string
  ): Promise<SoloAgent> {
    const result = await this.functionsWrapper.callFunction("updateSoloAgent", {
      agent,
      agentId,
      teamId,
    });
    return parseDates(result) as SoloAgent;
  }

  async deleteSoloAgent(teamId: string, agentId: string): Promise<void> {
    await this.functionsWrapper.callFunction("deleteSoloAgent", {
      teamId,
      agentId,
    });
  }

  async publishSoloAgent(agentId: string, teamId: string): Promise<SoloAgent> {
    const result = await this.functionsWrapper.callFunction(
      "publishSoloAgent",
      {
        agentId,
        teamId,
      }
    );
    return parseDates(result) as SoloAgent;
  }
}
