import React, { useEffect, useCallback } from "react";
import { createPortal } from "react-dom";

interface CommonModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  children: React.ReactNode;
}

export const CommonModal: React.FC<CommonModalProps> = ({
  isOpen,
  onDismiss,
  children,
}) => {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onDismiss();
      }
    },
    [onDismiss]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, handleKeyDown]);

  const handleBackgroundClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (event.target === event.currentTarget) {
        onDismiss();
      }
    },
    [onDismiss]
  );

  if (!isOpen) return null;

  const modalContent = (
    <div
      className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50 overflow-y-auto"
      onClick={handleBackgroundClick}
    >
      <div
        className="relative max-h-[90%] bg-white rounded-lg shadow-lg"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );

  // Create portal to render the modal at the document body level
  return createPortal(modalContent, document.body);
};
