import React, { useEffect, useState } from "react";
import useImagePreloader from "./ImagePreloader";
import { CarouselAction } from "../../models/CarouselData";
import { useTeams } from "../../contexts/TeamContext";

interface BannerCarouselProps {}

const BannerCarousel: React.FC<BannerCarouselProps> = () => {
  const teamService = useTeams();
  const carouselItems = teamService.currentCarouselData;
  useImagePreloader(carouselItems.map((bp) => bp.imageUrl));
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  useEffect(() => {
    if (carouselItems.length === 0) return;

    const interval = setInterval(() => {
      setCurrentPageIndex(
        (prevIndex) => (prevIndex + 1) % carouselItems.length
      );
    }, 10000);

    return () => clearInterval(interval);
  }, [carouselItems]);

  const data = carouselItems[currentPageIndex];

  const handleNext = () => {
    if (carouselItems.length === 0) return;
    const nextIndex =
      currentPageIndex + 1 == carouselItems.length ? 0 : currentPageIndex + 1;
    setCurrentPageIndex(nextIndex);
  };

  const handleBack = () => {
    if (carouselItems.length === 0) return;
    const backIndex =
      currentPageIndex == 0 ? carouselItems.length - 1 : currentPageIndex - 1;
    setCurrentPageIndex(backIndex);
  };

  const handleAction = (action: CarouselAction) => {
    if (action.type == "intercom") {
      window.Intercom("showNewMessage", action.title);
    } else if (action.type == "link") {
      window.open(action.link, "_blank");
    }
  };

  if (!data || carouselItems.length === 0) {
    return null; // Or return a placeholder/loading state
  }

  return (
    <div
      className={`${data.backgroundColor} transition-all duration-200 flex px-0 overflow-clip items-center w-full h-48 rounded-lg`}
    >
      <div className="px-6 flex items-center justify-center">
        <BannerNavButton onClick={() => handleBack()} back={true} />
      </div>

      <div className="flex px-4 flex-row items-center w-full justify-between">
        <div className="flex flex-col gap-2">
          <div
            className={`font-gooper text-lg md:text-3xl ${
              data.titleColor ?? "text-gray-900"
            }`}
          >
            {data.title}
          </div>
          <div
            className={`text-sm md:text-lg ${
              data.subtitleColor ?? "text-gray-700"
            }`}
          >
            {data.subtitle}
          </div>
          <div className="pt-2">
            <button
              className={`${data.action.color} items-center text-sm gap-1 border-1 flex flex-row px-3 py-2 rounded-lg`}
              onClick={() => {
                handleAction(data.action);
              }}
            >
              {data.action.title}
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 12H5m14 0-4 4m4-4-4-4"
                />
              </svg>
            </button>
          </div>
        </div>
        <></>
        <div className="w-80 h-80 relative items-center justify-center hidden lg:block">
          <img
            className="absolute z-10 h-full w-full object-contain object-center cursor-pointer"
            src={data.imageUrl}
            alt={`image for banner scene: ${data.title}`}
            onClick={() => handleAction(data.action)}
          />
        </div>
      </div>
      <div className="w-24 flex items-center justify-center">
        <BannerNavButton onClick={() => handleNext()} />
      </div>
    </div>
  );
};

const BannerNavButton: React.FC<{ back?: boolean; onClick: () => void }> = ({
  onClick,
  back,
}) => {
  return (
    <button
      onClick={onClick}
      className="w-9 h-9 items-center flex justify-center bg-gray-50 rounded-full"
    >
      <svg
        className="w-6 h-6 text-gray-400"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
          d={`${back ? "m15 19-7-7 7-7" : "m9 5 7 7-7 7"}`}
        />
      </svg>
    </button>
  );
};

export default BannerCarousel;
