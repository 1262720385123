import { WorkflowVersion } from "../../../../models/Workflow";
import { CommonInputSegment } from "../../../Common/CommonInput";
import { DecisionNodeData } from "../../Map/Nodes/DecisionNode";
import { useReactFlow } from "reactflow";
import VariableInputSelector from "../VariableInputSelector";
import { ExternalPath } from "../../../../models/ExternalPath";
import { OperatorSelector } from "./OperatorSelector";
import { Explainer } from "./Explainer";

export const SidebarDecisionDetail: React.FC<{
  version: WorkflowVersion;
  data: DecisionNodeData;
}> = ({ data, version }) => {
  const { setNodes } = useReactFlow();

  const updateNode = (newData: Partial<DecisionNodeData>) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === data.id) {
          return { ...node, data: { ...node.data, ...newData } };
        }
        return node;
      })
    );
  };

  return (
    <div className="flex flex-col gap-2">
      <CommonInputSegment
        title="Decision Title"
        onChange={(t) => updateNode({ title: t })}
        value={data.title}
        placeholder="Step Title"
        error={undefined}
        setError={undefined}
        id="beamTitle"
        className="font-sans text-gray-700"
        rightItem={
          <a
            href={ExternalPath.agentDocs()}
            target="_blank"
            rel="noopener noreferrer"
            className="underline text-sm text-blue-500 hover:text-blue-600 cursor-pointer"
          >
            Learn more about decisions
          </a>
        }
      />

      <div className="text-sm text-gray-700 font-medium pt-2">If...</div>
      <VariableInputSelector
        availableVariables={Object.values(version.usedVariables ?? {}).map(
          (uv) => uv.id
        )}
        selectedInput={data.selectedInput ?? { type: "variable" }}
        setSelectedInput={(selectedInput) => {
          updateNode({ selectedInput });
        }}
        currentNodeId={data.id}
      />
      <div className="text-sm text-gray-700 font-medium pt-2">operator </div>

      <OperatorSelector
        currentOperator={data.selectedOperator ?? "=="}
        selectedOperator={(o) => {
          updateNode({
            selectedOperator: o,
          });
        }}
      />

      <div className="text-sm text-gray-700 font-medium pt-2">
        when compared to...{" "}
      </div>

      <VariableInputSelector
        availableVariables={Object.values(version.usedVariables ?? {}).map(
          (uv) => uv.id
        )}
        selectedInput={data.selectedOperand ?? { type: "text" }}
        setSelectedInput={(selectedOperand) => {
          updateNode({ selectedOperand });
        }}
        currentNodeId={data.id}
      />
      <Explainer
        headline="Tip:"
        items={[
          "Select thing to compare",
          "Select how you want to compare",
          "Select thing to compare against",
        ]}
      />
    </div>
  );
};
