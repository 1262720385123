import { ReactNode, useEffect, useState } from "react";
import { CommonInputSegment } from "./CommonInput";

interface ControlledInputProps {
  defaultValue?: string;
  onUpdate?: (value: string) => void;
  title: string;
  subtitle?: string;
  disabled?: boolean;
  type?: "password" | "email" | "text";
  placeholder?: string;
  error?: string;
  setError?: (error: string) => void;
  rightItem?: ReactNode;
  id?: string;
  className?: string;
  leftItem?: ReactNode;
}

export const ControlledInput: React.FC<ControlledInputProps> = ({
  defaultValue = "",
  onUpdate,
  ...props
}) => {
  const [internalValue, setInternalValue] = useState(defaultValue);

  // Only set the internal value from the default value once on mount
  useEffect(() => {
    setInternalValue(defaultValue);
  }, []); // Empty dependency array means this only runs once on mount

  const handleChange = (value: string) => {
    setInternalValue(value);
    onUpdate?.(value);
  };

  return (
    <CommonInputSegment
      {...props}
      value={internalValue}
      onChange={handleChange}
    />
  );
};
