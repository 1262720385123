import { WorkflowDemoWebsiteResult } from "../../../../../models/WorkflowDemoResult";
import { formatDate, formatTime } from "../../../../../utils/DateUtils";
import Icon from "../../../../Icon";

interface WebsiteResultModalProps {
  result: WorkflowDemoWebsiteResult;
  shows: boolean;
  onClose: () => void;
}

export const WebsiteResultModal: React.FC<WebsiteResultModalProps> = ({
  result,
  shows,
  onClose,
}) => {
  const statusColor = (): string => {
    switch (result.status) {
      case "COMPLETED":
        return "bg-green-50 text-green-400";
      case "ERROR":
        return "bg-yellow-50 text-yellow-400";
      case "IN_PROGRESS":
        return "bg-red-50 text-red-400";
      default:
        return "";
    }
  };

  const statusTitle = (): string => {
    switch (result.status) {
      case "COMPLETED":
        return "success";
      case "ERROR":
        return "error";
      case "IN_PROGRESS":
        return "running";
      default:
        return "";
    }
  };

  if (!shows) {
    return null;
  }

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50 overflow-y-scroll"
      onClick={(e) => {
        e.stopPropagation();
        onClose();
      }}
    >
      <div className="bg-white rounded-lg flex flex-col shadow-lg w-[544px] relative overflow-y-auto">
        <div className="flex justify-end">
          <button
            className="text-gray-200 hover:text-gray-300 pt-8 pr-6"
            onClick={onClose}
          >
            <Icon type="x" className="size-5" />
          </button>
        </div>
        <div className="flex flex-col w-full overflow-y-auto p-6 gap-6">
          <div className="flex justify-between items-center">
            <div className="flex justify-between text-xs text-gray-500 gap-2">
              <span>Created</span>
              <span>{formatDate(result.createdAt)}</span>
              <span>{formatTime(result.createdAt)}</span>
            </div>
            <div
              className={`text-xs font-medium px-2 py-1 rounded ${statusColor()}`}
            >
              {statusTitle()}
            </div>
          </div>

          <div className="flex flex-col gap-1 justify-center items-center">
            <div className="text-gray-500 text-xs">Website Result</div>
            <span className="text-gray-900 text-2xl font-gooper">
              {result.name}
            </span>
          </div>

          <WebsiteDetailsBlock
            url={result.url}
            filterOptions={result.filterOptions}
          />

          <ContentBlock
            content={result.processedContent}
            error={result.error}
          />
        </div>
      </div>
    </div>
  );
};

const WebsiteDetailsBlock: React.FC<{
  url: string;
  filterOptions: WorkflowDemoWebsiteResult["filterOptions"];
}> = ({ url, filterOptions }) => {
  return (
    <div className="border border-gray-200 w-full bg-gray-50 rounded-lg flex-col gap-2 inline-flex">
      <div className="flex items-center gap-2 p-3 border-b border-gray-200">
        <Icon type="globe" className="text-blue-500 size-4" />
        <div className="font-gooper text-gray-700 text-sm">Website Details</div>
      </div>
      <div className="p-3 flex flex-col gap-3">
        <div className="flex items-center gap-2">
          <span className="text-sm font-medium text-gray-600">URL</span>
          <span className="text-sm text-gray-700 font-mono break-all">
            {url}
          </span>
        </div>

        <div className="flex flex-col gap-1">
          <span className="text-sm font-medium text-gray-600">
            Filter Options
          </span>
          <div className="grid grid-cols-2 gap-2">
            {Object.entries(filterOptions).map(
              ([key, value]) =>
                key !== "customSelectors" && (
                  <div key={key} className="flex items-center gap-2">
                    <Icon
                      type={value ? "check" : "x"}
                      className={`size-4 ${
                        value ? "text-green-500" : "text-gray-300"
                      }`}
                    />
                    <span className="text-sm text-gray-700">
                      {key.replace(/([A-Z])/g, " $1").toLowerCase()}
                    </span>
                  </div>
                )
            )}
          </div>
          {filterOptions.customSelectors?.length > 0 && (
            <div className="mt-2">
              <span className="text-sm font-medium text-gray-600">
                Custom Selectors
              </span>
              <div className="font-mono text-sm text-gray-700">
                {filterOptions.customSelectors.map((selector) => (
                  <div key={selector}>{selector}</div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ContentBlock: React.FC<{
  content?: string;
  error?: string;
}> = ({ content, error }) => {
  return (
    <div className="border border-gray-200 w-full bg-gray-50 rounded-lg flex-col gap-2 inline-flex">
      <div className="flex items-center gap-2 p-3 border-b border-gray-200">
        <Icon type="api" className="text-blue-500 size-4" />
        <div className="font-gooper text-gray-700 text-sm">Content</div>
      </div>
      <div className="p-3 flex flex-col gap-3">
        {error && (
          <div className="flex flex-col gap-1">
            <span className="text-sm font-medium text-red-500">Error</span>
            <div className="text-sm text-red-700">{error}</div>
          </div>
        )}
        {content && (
          <div className="flex flex-col gap-1">
            <span className="text-sm font-medium text-gray-600">
              Processed Content
            </span>
            <div className="font-mono text-sm text-gray-700 whitespace-pre-wrap max-h-96 overflow-y-auto">
              {content}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
