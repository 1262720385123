import React, { useEffect, useState } from "react";
import CommonHeader from "../Common/CommonHeader";
import { useParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { TeamAlert } from "../../models/TeamAlert";
import CheckFilter, { CheckFilterItem } from "./CheckFilter";
import { useTeams } from "../../contexts/TeamContext";
import { Team } from "../../models/Team";
import NotificationRow from "./NotificationRow";
import DateRangeFilter, { DateRange } from "./DateRangeFilter";
import { CommonSpinner } from "../Common/CommonLoading";

const Notifications: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [alerts, setAlerts] = useState<TeamAlert[]>([]);
  const [filterDateRange, setFilterDateRange] = useState<DateRange | null>(
    null
  );
  const [teamFilterItems, setTeamFilterItems] = useState<CheckFilterItem[]>([]);
  const [categoryFilterItems, setCategoryFilterItems] = useState<
    CheckFilterItem[]
  >([
    {
      name: "Team Alerts",
      id: "team",
      selected: true,
    },
    {
      name: "Billing",
      id: "billing",
      selected: true,
    },
    {
      name: "Subscription",
      id: "subscription",
      selected: true,
    },
    {
      name: "General",
      id: "general",
      selected: true,
    },
  ]);

  const authService = useAuth();
  const teamService = useTeams();

  useEffect(() => {
    const loadData = async () => {
      const currentUser = await authService.currentUser();

      if (!currentUser?.teams) {
        return;
      }

      const activeTeamIds = Object.entries(currentUser!.teams!)
        .filter(([_, isActive]) => isActive)
        .map(([teamId, _]) => {
          return teamService.teamRepo.get(teamService.teamPath(), teamId);
        });

      const allFetchedTeams = await Promise.allSettled(activeTeamIds);
      const aliveFetched = allFetchedTeams.map((p) => {
        if (p.status == "fulfilled") {
          return p.value;
        } else {
          return null;
        }
      });
      const activeTeams = aliveFetched.filter((t) => t != null) as Team[];
      const filterItems = activeTeams.map((t) => {
        return { name: t.name, id: t.id!, selected: true };
      });
      setTeamFilterItems(filterItems);
    };
    loadData();
  }, []);

  useEffect(() => {
    const unsubscribe = authService.alertRepo.observeList(
      authService.alertsPath(authService.userId!),
      async (fetched) => {
        setAlerts(fetched);
        setIsLoading(false);
        const updates = fetched
          .filter((a) => !a.isRead)
          .map((a) => {
            return authService.alertRepo.update(
              { isRead: true },
              authService.alertsPath(authService.userId!),
              a.id
            );
          });
        await Promise.all(updates);
      },
      { name: "createdAt", descending: true }
    );
    return () => unsubscribe();
  }, [teamId, authService]);

  const updateTeamFilterItem = (item: CheckFilterItem) => {
    item.selected = !item.selected;
    setTeamFilterItems([...teamFilterItems]);
  };

  const updateCategoryFilterItem = (item: CheckFilterItem) => {
    item.selected = !item.selected;
    setCategoryFilterItems([...categoryFilterItems]);
  };

  const activeIds = teamFilterItems
    .filter((tf) => tf.selected)
    .map((tf) => tf.id);
  const activeCategories = categoryFilterItems
    .filter((cf) => cf.selected)
    .map((cf) => cf.id);
  const filteredNotifications = alerts.filter((alert) => {
    const withinDateRange = filterDateRange
      ? alert.createdAt > filterDateRange!.startDate &&
        alert.createdAt < filterDateRange.endDate
      : true;
    return (
      activeIds.includes(alert.teamId) &&
      activeCategories.includes(alert.type) &&
      withinDateRange
    );
  });

  return (
    <div className="flex overflow-y-scroll flex-col p-16 w-full h-svh bg-gray-50">
      <CommonHeader
        title={`Notifications & Easybeam Pulse`}
        subtitle="Stay in the loop with all the good, the bad, and the ai-generated. Use the filters to find what you're after."
        sections={[{ name: "Notifications", link: "/" }]}
        teamId={teamId!}
        actions={[]}
      />
      <div className="grid grid-cols-3 pt-6 gap-3">
        <CheckFilter
          typeName="Team"
          typeNamePlural="All Teams"
          items={teamFilterItems}
          updatedItem={updateTeamFilterItem}
          leftIcon="users"
        />
        <CheckFilter
          typeName="Category"
          typeNamePlural="All Categories"
          items={categoryFilterItems}
          updatedItem={updateCategoryFilterItem}
          leftIcon="filter"
        />
        <DateRangeFilter
          dateRange={filterDateRange}
          selectedDateRange={setFilterDateRange}
        />
      </div>
      <div className="flex pt-4 flex-col gap-2 w-full h-svh bg-gray-50">
        {isLoading && <CommonSpinner />}
        {filteredNotifications.map((a) => {
          return <NotificationRow alert={a} key={a.id} />;
        })}
      </div>
    </div>
  );
};

export default Notifications;
