import { useReactFlow } from "reactflow";
import { Team } from "../../../../models/Team";
import { WorkflowVersion } from "../../../../models/Workflow";
import { CommonInputSegment } from "../../../Common/CommonInput";
import Icon from "../../../Icon";
import { APINodeData } from "../../Map/Nodes/APINode";
import { findAvailableOutputNodes } from "../../Map/Nodes/NodeUtils";
import { APIBodyInput } from "./APIBodyInput";
import { APISingleLineInput } from "./APISingleLineInput";

export const SidebarAPIDetail: React.FC<{
  data: APINodeData;
  updateNode: (newData: Partial<APINodeData>) => void;
  team: Team;
  version: WorkflowVersion;
}> = ({ data, updateNode, team, version }) => {
  const { getNodes, getEdges } = useReactFlow();

  const teamSecrets = Object.keys(team.customSecretsUsed ?? {});
  const userSecrets = Object.keys(version.userSecrets ?? {});
  const variables = Object.keys(version.usedVariables ?? {});
  const nodes = getNodes();
  const edges = getEdges();
  const outputs = findAvailableOutputNodes(data.id, nodes, edges);

  return (
    <div className="flex flex-col gap-4">
      <CommonInputSegment
        title="API Step Title"
        onChange={(t) => updateNode({ title: t })}
        value={data.title}
        placeholder="Step Title"
        error={undefined}
        setError={undefined}
        id="beamTitle"
        className="font-sans text-gray-700"
      />
      <div className="flex flex-col gap-2">
        <div className="text-sm font-medium text-gray-700">Method</div>
        <select
          value={data.method}
          onChange={(e) => updateNode({ method: e.target.value as any })}
          className="p-2 border border-gray-200 rounded-md bg-gray-50"
        >
          <option value="GET">GET</option>
          <option value="POST">POST</option>
          <option value="PUT">PUT</option>
          <option value="DELETE">DELETE</option>
        </select>
      </div>
      <APISingleLineInput
        teamSecrets={teamSecrets}
        userSecrets={userSecrets}
        variables={variables}
        value={data.url}
        updated={(url) => updateNode({ url })}
        outputs={outputs}
        placeholder="https://api.example.com/endpoint"
        currentNodeId={data.id}
      />
      <div className="flex flex-col gap-2">
        <div className="flex flex-row justify-between items-center">
          <div className="text-sm font-medium text-gray-700">Headers</div>
          <button
            onClick={() => {
              updateNode({
                headers: { ...data.headers, "": "" },
              });
            }}
            className="p-2 text-blue-500 hover:bg-blue-50 rounded-md"
          >
            <Icon type="plus" className="size-6" />
          </button>
        </div>
        {Object.entries(data.headers).map(([key, value], index) => (
          <div className="flex flex-row">
            <div
              key={`header-${index}`}
              className="grid grid-cols-2 gap-2 flex-1"
            >
              <APISingleLineInput
                teamSecrets={teamSecrets}
                userSecrets={userSecrets}
                variables={variables}
                outputs={outputs}
                value={key}
                updated={(newKey) => {
                  const entries = Object.entries(data.headers);
                  entries[index] = [newKey, value];
                  const newHeaders = Object.fromEntries(entries);
                  updateNode({ headers: newHeaders });
                }}
                placeholder="Header"
                currentNodeId={data.id}
              />
              <APISingleLineInput
                teamSecrets={teamSecrets}
                userSecrets={userSecrets}
                variables={variables}
                value={value}
                outputs={outputs}
                updated={(newValue) => {
                  updateNode({
                    headers: { ...data.headers, [key]: newValue },
                  });
                }}
                placeholder="Value"
                currentNodeId={data.id}
              />
            </div>
            <button
              onClick={() => {
                const newHeaders = { ...data.headers };
                delete newHeaders[key];
                updateNode({ headers: newHeaders });
              }}
              className="ml-2 p-2 text-red-200 hover:bg-red-50 rounded-md items-center justify-center"
            >
              <Icon type="x-no-circle" />
            </button>
          </div>
        ))}
      </div>
      <div className="flex flex-col gap-2">
        <div className="text-sm font-medium text-gray-700">Request Body</div>
        <APIBodyInput
          outputs={outputs}
          teamSecrets={teamSecrets}
          userSecrets={userSecrets}
          variables={variables}
          body={data.body || ""}
          updated={(body) => updateNode({ body })}
          currentNodeId={data.id}
        />
      </div>
    </div>
  );
};
