import React, { useState } from "react";

interface DemoSecretsInputProps {
  userSecrets?: { [key: string]: boolean };
  demoSecrets?: { [key: string]: string };
  onUpdateDemoSecrets: (secrets: { [key: string]: string }) => void;
}

export const DemoSecretsInput: React.FC<DemoSecretsInputProps> = ({
  userSecrets = {},
  demoSecrets = {},
  onUpdateDemoSecrets,
}) => {
  const [secretValues, setSecretValues] = useState<{ [key: string]: string }>(
    demoSecrets
  );

  const handleSecretChange = (secret: string, value: string) => {
    const newSecretValues = {
      ...secretValues,
      [secret]: value,
    };
    setSecretValues(newSecretValues);
    onUpdateDemoSecrets(newSecretValues);
  };

  const secrets = Object.keys(userSecrets);
  if (secrets.length === 0) return null;

  return (
    <div className="flex flex-col gap-1">
      <div className="text-sm text-gray-700 font-medium">
        Preview User Secret Values
      </div>
      <div className="text-xs text-gray-400">
        Required for testing & previewing
      </div>
      <div className="flex flex-col gap-2 mt-2">
        {secrets.map((secret) => (
          <div key={secret} className="flex flex-col gap-1">
            <label
              htmlFor={`demo-secret-${secret}`}
              className="text-sm text-gray-600"
            >
              {secret}
            </label>
            <input
              id={`previewSecret${secret}`}
              type="text"
              value={secretValues[secret] || ""}
              onChange={(e) => handleSecretChange(secret, e.target.value)}
              className="border-gray-300 w-full focus:border-blue-500 bg-gray-50 text-gray-700 border rounded-md p-2 leading-tight text-sm focus:ring-0 focus:outline-none h-10"
              placeholder={`Enter value for ${secret}`}
              autoComplete="off"
            />
          </div>
        ))}
      </div>
    </div>
  );
};
