import { PortalExampleSection } from "./PortalExampleSection";
import { WorkflowExampleSection } from "./WorkflowExampleSection";

interface ExamplesSectionProps {
  teamId: string;
}

export const ExamplesSection: React.FC<ExamplesSectionProps> = ({ teamId }) => {
  return (
    <div className="space-y-8">
      <div>
        <h1 className="text-2xl font-bold mb-6">Example Content Management</h1>
        <p className="text-gray-600 mb-8">
          Manage example prompts and agents that will be available to new teams.
        </p>
      </div>

      <PortalExampleSection teamId={teamId} />
      <WorkflowExampleSection teamId={teamId} />
    </div>
  );
};
