import React, { useState, useEffect, useRef } from "react";
import { ChatMessage } from "../../models/ChatMessage";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import { ChatBubble } from "../Portals/Portal Detail/ChatBubble";
import Icon from "../Icon";
import { SoloAgent } from "../../models/SoloAgent";
import { Easybeam } from "easybeam-react";
import { CommonSpinner } from "../Common/CommonLoading";

interface AgentChatProps {
  messages: ChatMessage[];
  running: boolean;
  sendMessage: (message: string) => void;
  agent: SoloAgent;
  chatId: string | undefined;
  easybeam: Easybeam;
  userId?: string;
}

export const AgentChat: React.FC<AgentChatProps> = ({
  agent,
  messages,
  running,
  sendMessage,
  chatId,
  easybeam,
  userId,
}) => {
  const [sendState, setSendState] = useState<AnimationState>("ready");
  const [newMessage, setNewMessage] = useState("");
  const [hasReviewed, setHasReviewed] = useState(false);
  const [isReviewing, setIsReviewing] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages, running]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleSendMessage = () => {
    if (newMessage.trim().length === 0) return;
    sendMessage(newMessage.trim());
    setNewMessage("");
  };

  const handleReview = async (score: number) => {
    if (chatId) {
      setIsReviewing(true);
      try {
        await easybeam.review(chatId, userId, score, undefined);
        setHasReviewed(true);
      } catch (error) {
        console.error("Error submitting review:", error);
      } finally {
        setIsReviewing(false);
      }
    }
  };

  const disableChat = newMessage.trim().length < 1 || running;

  const headerClassName = `p-4 ${
    !agent.published?.themeColor
      ? "bg-gradient-to-br from-blue-500 to-green-400"
      : ""
  }`;

  const headerStyle = agent.published?.themeColor
    ? { backgroundColor: agent.published.themeColor }
    : {};

  const titleColorStyle = agent.published?.titleColor
    ? { color: agent.published.titleColor }
    : { color: "#FFFFFF" };

  return (
    <div className="flex flex-col h-full bg-gray-50">
      <div
        className={`sticky top-0 z-10 ${headerClassName}`}
        style={headerStyle}
      >
        <div className="max-w-3xl mx-auto">
          <h2 className="text-4xl font-gooper mb-2" style={titleColorStyle}>
            {agent.published?.title}
          </h2>
          <span
            className="text-xs"
            style={{
              color: agent.published?.titleColor || "rgb(243 244 246)",
            }}
          >
            made with{" "}
            <a
              href="https://www.easybeam.ai"
              target="_blank"
              rel="noopener noreferrer"
              className={`font-gooper transition-colors hover:opacity-80`}
              style={{
                color: agent.published?.titleColor || "rgb(243 244 246)",
              }}
            >
              easybeam.ai
            </a>
          </span>
        </div>
      </div>

      <div className="flex-grow overflow-hidden">
        <div className="h-full max-w-4xl mx-auto flex flex-col">
          <div className="flex-grow overflow-y-auto p-4">
            {messages.length === 0 && (
              <div className="text-center text-gray-500 mt-10">
                <p>
                  {agent.published?.description ||
                    "Start the conversation by typing a message below."}
                </p>
              </div>
            )}
            {messages.map((message, index) => (
              <ChatBubble
                hideCost={true}
                teamName=""
                key={message.id}
                message={message}
                isLast={index === messages.length - 1}
                loading={
                  running &&
                  index === messages.length - 1 &&
                  message.role === "AI"
                }
              />
            ))}
            <div ref={messagesEndRef} />
          </div>

          <div className="p-4 mb-4">
            {chatId && (
              <div className="flex justify-end items-center gap-2 mb-2 text-sm text-gray-500">
                {hasReviewed ? (
                  <span>Thanks for the feedback!</span>
                ) : isReviewing ? (
                  <>
                    <span>Submitting review</span>
                    <CommonSpinner size="xs" />
                  </>
                ) : (
                  <>
                    <span>Rate this agent:</span>
                    <button
                      onClick={() => handleReview(10)}
                      className="hover:text-gray-700 transition-colors"
                      aria-label="Rate positively"
                    >
                      <Icon type="thumbs-up" className="size-4" />
                    </button>
                    <button
                      onClick={() => handleReview(0)}
                      className="hover:text-gray-700 transition-colors"
                      aria-label="Rate negatively"
                    >
                      <Icon type="thumbs-down" className="size-4" />
                    </button>
                  </>
                )}
              </div>
            )}
            <div className="bg-white shadow-lg rounded-lg p-4">
              <textarea
                className="w-full h-20 p-2 border border-gray-200 rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                placeholder="Type your message..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <div className="flex justify-end mt-2 w-full">
                <AnimatedButton
                  title="Send"
                  onClick={handleSendMessage}
                  buttonState={sendState}
                  setButtonState={setSendState}
                  style="action"
                  classNameIn="w-full flex justify-center"
                  disabled={disableChat}
                  leftChild={
                    <Icon type="paper-plane" className="rotate-90 size-4" />
                  }
                  font="font-sans font-medium"
                  id="sendMessageButton"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
