import React from "react";
import { WorkflowLog } from "../../models/WorkflowLog";
import { CommonSpinner } from "../Common/CommonLoading";
import Icon from "../Icon";
import { TableHeader } from "./TableHeader";

interface WorkflowLogTableProps {
  logs: WorkflowLog[];
  loading: boolean;
  onRowClick: (log: WorkflowLog) => void;
}

export const WorkflowLogTable: React.FC<WorkflowLogTableProps> = ({
  logs,
  loading,
  onRowClick,
}) => {
  return (
    <table className="border rounded-lg border-gray-500 w-full overflow-hidden bg-gray-200">
      <thead className="border-b border-gray-200">
        <tr>
          <TableHeader title="Status" className="hidden md:table-cell" />
          <TableHeader title="Agent" />
          <TableHeader title="Version Name" className="hidden md:table-cell" />
          <TableHeader title="Created" />
          <TableHeader title="Latency" className="hidden lg:table-cell" />
          <TableHeader title="User ID" className="hidden md:table-cell" />
          <TableHeader title="Cost" className="hidden lg:table-cell" />
          <TableHeader title="Steps" className="hidden md:table-cell" />
        </tr>
      </thead>
      {loading && <LoadingTableBody />}
      <tbody>
        {logs.map((log) => (
          <tr
            key={log.chatId}
            className={`hover:bg-gray-100 cursor-pointer ${
              log.status === "ERROR"
                ? "bg-yellow-50"
                : log.status === "SECURITY_WARNING"
                ? "bg-red-50"
                : "bg-gray-0"
            }`}
            onClick={() => onRowClick(log)}
          >
            <td className="border-b border-gray-200 p-3 font-medium text-sm hidden md:table-cell">
              <Icon
                className={`${
                  log.status === "ERROR"
                    ? "text-yellow-300"
                    : log.status === "SECURITY_WARNING"
                    ? "text-red-300"
                    : "text-green-300"
                }`}
                type={
                  log.status === "ERROR"
                    ? "alert"
                    : log.status === "SECURITY_WARNING"
                    ? "shield"
                    : "check"
                }
              />
            </td>
            <td className="border-b border-gray-200 p-3 font-medium text-sm">
              {log.workflowName}
            </td>
            <td className="border-b border-gray-200 p-3 font-medium text-sm hidden md:table-cell">
              {log.versionName}
            </td>
            <td className="border-b p-3 text-sm text-gray-500 font-medium">
              {new Date(log.createdAt)
                .toLocaleDateString("en-GB", {
                  second: "numeric",
                  minute: "numeric",
                  hour: "numeric",
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                })
                .replace(",", "")}
            </td>
            <td className="border-b border-gray-200 p-3 font-medium text-sm hidden lg:table-cell">
              {log.latency ? `${log.latency}ms` : "-"}
            </td>
            <td className="border-b border-gray-200 p-3 font-medium text-sm hidden md:table-cell">
              {log.userId || "-"}
            </td>
            <td className="border-b border-gray-200 p-3 font-medium text-sm hidden lg:table-cell">
              {log.response?.cost?.toLocaleString() || "-"}
            </td>
            <td className="border-b border-r border-gray-200 p-3 font-medium text-sm hidden md:table-cell">
              {log.steps?.length?.toLocaleString() || "-"}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const LoadingTableBody: React.FC = () => (
  <tbody>
    <tr className="bg-gray-0 hover:bg-gray-100">
      <td className="border-b border-l border-gray-200 p-3 font-medium text-sm">
        <CommonSpinner />
      </td>
      <td className="border-b border-gray-200 p-3 font-medium text-sm">
        <CommonSpinner />
      </td>
      <td className="border-b border-gray-200 p-3 font-medium text-sm">
        <CommonSpinner />
      </td>
      <td className="border-b border-gray-200 p-3 font-medium text-sm">
        <CommonSpinner />
      </td>
      <td className="border-b border-gray-200 p-3 font-medium text-sm hidden lg:table-cell">
        <CommonSpinner />
      </td>
      <td className="border-b border-gray-200 p-3 font-medium text-sm hidden md:table-cell">
        <CommonSpinner />
      </td>
      <td className="border-b border-gray-200 p-3 font-medium text-sm hidden lg:table-cell">
        <CommonSpinner />
      </td>
      <td className="border-b border-r border-gray-200 p-3 font-medium text-sm hidden md:table-cell">
        <CommonSpinner />
      </td>
    </tr>
  </tbody>
);
