import { useState, useEffect, useRef } from "react";
import { ChatMessage } from "../../../models/ChatMessage";
import AnimatedButton, { AnimationState } from "../../AnimatedButton";
import Icon from "../../Icon";
import { ChatBubble } from "./ChatBubble";

export const DemoChat: React.FC<{
  messages: ChatMessage[];
  running: boolean;
  teamName: string;
  sendMessage: (message: string) => void;
}> = ({ messages, running, teamName, sendMessage }) => {
  const [sendState, setSendState] = useState<AnimationState>("ready");
  const [newMessage, setNewMessage] = useState("");
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  const handleSendMessage = () => {
    sendMessage(newMessage);
    setNewMessage("");
  };

  const disableChat = newMessage.length < 1;

  return (
    <div className="flex flex-col h-full">
      <div
        className="flex-grow rounded-t flex flex-col gap-4 p-4 overflow-y-auto bg-gray-50 shadow-[inset_0_-4px_4px_-2px_rgba(0,0,0,0.1),_inset_0_-2px_4px_-1px_rgba(0,0,0,0.06)]"
        ref={messagesContainerRef}
      >
        {messages.length === 0 && (
          <div className="flex flex-col gap-2 p-2 text-sm">
            <div className="text-gray-500">
              Press Preview to see your Prompt in action and see what your
              prompt will generate.
            </div>
            <div className="text-gray-500">
              Respond with a reply below to keep the conversation going.
            </div>
          </div>
        )}
        {messages.map((message, index) => (
          <ChatBubble
            loading={running}
            teamName={teamName}
            message={message}
            key={message.id}
            isLast={index + 1 === messages.length}
            id={`message${index + 1}`}
          />
        ))}
      </div>
      <div className="flex flex-col bg-gray-50 border-t border-gray-200 rounded-b">
        <textarea
          className="bg-gray-50 placeholder-gray-500 border-0 h-20 focus:border-transparent focus:ring-0 focus:outline-none resize-none"
          id="message"
          placeholder="Write a reply ..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <div className="flex flex-row pt-2">
          <AnimatedButton
            title={`Send message`}
            onClick={() => handleSendMessage()}
            buttonState={sendState}
            setButtonState={setSendState}
            style={"action"}
            key="send"
            classNameIn="flex-grow"
            disabled={disableChat}
            leftChild={<Icon type="paper-plane" className="rotate-90 size-4" />}
            font="font-sans font-medium"
            id="sendMessageButton"
          />
        </div>
      </div>
    </div>
  );
};
