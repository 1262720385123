import { useEffect, useRef, useState } from "react";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import FailureModal from "../FailureModal";
import { useTeams } from "../../contexts/TeamContext";
import { useAuth } from "../../contexts/AuthContext";
import { CommonConfirmationModal } from "../Common/CommonConfirmationModal";
import { TeamSettingsMemberChangeRoleModal } from "./TeamSettingsMemberChangeRoleModal";
import { TeamRole } from "../../models/Team";

export const TeamSettingsMemberMenu: React.FC<{
  teamId: string;
  shows: boolean;
  close: (from: number) => void;
  memberId: string;
  active: boolean;
  updated: () => void;
  index: number; // Adding index to the component props
  memberRole: TeamRole;
  memberName: string;
}> = ({
  teamId,
  close,
  shows,
  active,
  updated,
  memberId,
  index,
  memberRole,
  memberName,
}) => {
  const authService = useAuth();
  const teamService = useTeams();

  const refs = useRef<Map<number, HTMLDivElement>>(new Map()); // Using a Map to manage refs

  const [error, setError] = useState("");
  const [revokeInvitationState, setRevokeInvitationState] =
    useState<AnimationState>("ready");
  const [removeUserState, setRemoveUserState] =
    useState<AnimationState>("ready");
  const [changeRoleState, setChangeRoleState] =
    useState<AnimationState>("ready");
  const [resendInviteState, setResendInviteState] =
    useState<AnimationState>("ready");

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmRevoke, setShowConfirmRevoke] = useState(false);
  const [showChangeRole, setShowChangeRole] = useState(false);
  const [role, setRole] = useState<TeamRole>();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const currentRef = refs.current.get(index);
      if (currentRef && !currentRef.contains(event.target as Node)) {
        close(index);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [close, index]);

  const handleRevokeInvitation = async () => {
    setShowConfirmRevoke(false);
    setRevokeInvitationState("loading");
    try {
      await teamService.inviteRepo.delete(
        teamService.invitePath(teamId),
        memberId
      );
      setRevokeInvitationState("success");
      updated();
    } catch (error) {
      setRevokeInvitationState("error");
      setError("Failed to revoke invitation");
    }
    setRevokeInvitationState("success");
    updated();
  };

  const handleResendInvite = async () => {
    setResendInviteState("loading");
    try {
      await teamService.invite(teamId, memberId, memberRole, window.origin);
    } catch (e) {
      setResendInviteState("error");
    }
    setResendInviteState("success");
  };

  const handleRemoveUser = async () => {
    setShowConfirmDelete(false);
    const getCurrentUser = authService.currentUser();
    const getTeam = teamService.teamRepo.get(teamService.teamPath(), teamId);
    setRemoveUserState("loading");
    try {
      const [currentUser, team] = await Promise.all([getCurrentUser, getTeam]);
      if (!team || !currentUser) {
        throw new Error("no team!");
      }

      if (currentUser.id == memberId) {
        setError("Can't delete yourself.");
        setRemoveUserState("error");
        return;
      }
      const members = team.members ?? {};
      delete members[memberId];
      await teamService.teamRepo.update(
        { members },
        teamService.teamPath(),
        teamId
      );
    } catch (error) {
      setRemoveUserState("error");
    }
    updated();
    setRemoveUserState("success");
  };

  const handleChangeRole = async () => {
    setShowChangeRole(false);
    const getCurrentUser = authService.currentUser();
    const getTeam = teamService.teamRepo.get(teamService.teamPath(), teamId);
    setChangeRoleState("loading");
    if (!role) {
      return;
    }
    try {
      const [currentUser, team] = await Promise.all([getCurrentUser, getTeam]);
      if (!team || !currentUser) {
        throw new Error("no team!");
      }

      if (currentUser.id == memberId) {
        setError("Can't change your own role");
        setChangeRoleState("error");
        return;
      }
      const members = team.members ?? {};
      members[memberId].role = role;
      await teamService.teamRepo.update(
        { members },
        teamService.teamPath(),
        teamId
      );
    } catch (error) {
      setChangeRoleState("error");
    }
    setRole(undefined);
    setChangeRoleState("success");
    updated();
  };

  return (
    <div
      ref={(el) => refs.current.set(index, el!)} // Setting the ref using index
      className={`flex right-0 flex-col gap-1 w-40 absolute top-0 z-50 shadow-lg bg-gray-0 rounded-lg transition-all duration-200 overflow-clip ${
        shows ? "" : "hidden"
      }`}
    >
      <FailureModal
        title={"Something went wrong"}
        message={error}
        backButtonTitle={"Back to Prompts"}
        shows={error !== ""}
        closed={() => setError("")}
      />
      <CommonConfirmationModal
        isOpen={showConfirmDelete}
        title="Remove Team Member?"
        message="This person will be removed from your team, are you sure?"
        onCancel={() => setShowConfirmDelete(false)}
        onConfirm={() => handleRemoveUser()}
        confirmStyle={"destructive"}
      />
      <CommonConfirmationModal
        isOpen={showConfirmRevoke}
        title="Revoke invite?"
        message="This will revoke the pending invite, are you sure?"
        onCancel={() => setShowConfirmRevoke(false)}
        onConfirm={() => handleRevokeInvitation()}
        confirmStyle={"destructive"}
      />
      <TeamSettingsMemberChangeRoleModal
        isOpen={showChangeRole}
        onCancel={() => {
          setRole(undefined);
          setShowChangeRole(false);
        }}
        onConfirm={handleChangeRole}
        role={role || memberRole}
        setRole={setRole}
        memberName={memberName}
        teamId={teamId}
        memberId={memberId}
      />
      {active && (
        <AnimatedButton
          title={"Change Role"}
          onClick={() => setShowChangeRole(true)}
          buttonState={changeRoleState}
          setButtonState={setChangeRoleState}
          style={"transparent-action"}
          key="changeRole"
          leftIcon="user-edit"
          id={`editRoleButton${index}`}
          font={"font-base font-normal"}
        />
      )}
      {active && (
        <AnimatedButton
          title={"Remove User"}
          onClick={() => setShowConfirmDelete(true)}
          buttonState={removeUserState}
          setButtonState={setRemoveUserState}
          style={"transparent-destructive"}
          key="delete"
          leftIcon="trash"
          id={`deleteButton${index}`}
          font={"font-base font-normal"}
        />
      )}

      {!active && (
        <AnimatedButton
          title={"Invite Again"}
          onClick={() => handleResendInvite()}
          buttonState={resendInviteState}
          setButtonState={setResendInviteState}
          style={"transparent-black"}
          key="resend"
          leftIcon="redo"
          id={`resendButton${index}`}
          font={"font-base font-normal"}
        />
      )}
      {!active && (
        <AnimatedButton
          title={"Revoke Invite"}
          onClick={() => setShowConfirmRevoke(true)}
          buttonState={revokeInvitationState}
          setButtonState={setRevokeInvitationState}
          style={"transparent-destructive"}
          key="revoke"
          leftIcon="trash"
          id={`revokeButton${index}`}
          font={"font-base font-normal"}
        />
      )}
    </div>
  );
};
