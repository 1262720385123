import React from "react";
import { useReactFlow, NodeProps } from "reactflow";
import Icon from "../../../Icon";
import { createSelectorNode } from "./SelectorNode";
import { AddNodeHandler } from "../Handlers/AddNodeHandler";

export const WebsiteNodeType = "workflowWebsiteNodeType";

export interface WebsiteNodeData {
  id: string;
  teamId: string;
  type: typeof WebsiteNodeType;
  title: string;
  url: string;
  filterOptions: {
    removeScripts: boolean;
    removeStyles: boolean;
    removeNavigation: boolean;
    removeFooter: boolean;
    removeAds: boolean;
    keepMainContent: boolean;
    customSelectors: string[];
  };
}

export const WebsiteBadge: React.FC = () => {
  return (
    <div className="px-2.5 py-0.5 rounded justify-center items-center gap-1 flex bg-purple-50 text-purple-400">
      <Icon type="globe" className="size-3" />
      <div className="text-center text-xs font-medium leading-[18px]">
        Website
      </div>
    </div>
  );
};

export const WebsiteNode: React.FC<NodeProps<WebsiteNodeData>> = ({
  data,
  id,
}) => {
  const { getNode, setNodes, getEdges } = useReactFlow();

  const handleAdd = () => {
    const currentNode = getNode(id);
    if (!currentNode) return;

    const selectionNode = createSelectorNode(currentNode, data.teamId);
    setNodes((nds) => nds.concat(selectionNode));
  };

  const sourceConnected = () => {
    const edges = getEdges();
    return edges.some((edge) => edge.source === id);
  };

  const targetConnected = () => {
    const edges = getEdges();
    return edges.some((edge) => edge.target === id);
  };

  const selected = getNode(id)?.selected;
  const nodeClasses = `shadow group box-border border-2 ${
    selected ? "border-blue-500" : "border-transparent hover:border-blue-500"
  } flex flex-col gap-1 rounded-lg p-3 bg-gray-0 w-56 transition-all`;

  const getActiveFiltersCount = () => {
    const { filterOptions } = data;
    return Object.values(filterOptions).filter((value) =>
      Array.isArray(value) ? value.length > 0 : value
    ).length;
  };

  return (
    <div className={nodeClasses}>
      <AddNodeHandler
        type="target"
        isConnected={targetConnected()}
        id={`${id}_target`}
      />
      <div className="flex flex-row justify-between h-6">
        <WebsiteBadge />
      </div>
      <div className="text-2xl font-gooper text-gray-900 h-12 truncate">
        {data.title}
      </div>
      <div className="flex flex-col gap-1">
        <div className="text-sm text-gray-500 truncate">
          {data.url || "No URL Set"}
        </div>
        <div className="text-xs text-gray-400 truncate">
          {getActiveFiltersCount()} Active Filters
          {data.filterOptions.customSelectors.length > 0 &&
            ` • ${data.filterOptions.customSelectors.length} Custom Selectors`}
        </div>
      </div>
      <AddNodeHandler
        onClick={handleAdd}
        type="source"
        isConnected={sourceConnected()}
        id={`${id}_source`}
      />
    </div>
  );
};
