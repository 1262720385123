import { useReactFlow } from "reactflow";
import { Team } from "../../../../models/Team";
import { WorkflowVersion } from "../../../../models/Workflow";
import { CommonInputSegment } from "../../../Common/CommonInput";
import Icon from "../../../Icon";
import { ActionNodeData } from "../../Map/Nodes/ActionNode";
import { AIProviderList } from "../../../Portals/Portal Detail/AIProvider/AIProviderList";
import { ChatConfigurationTemplate } from "../../../../models/ChatConfigurationTemplate";
import { extractDefaultConfig } from "../../../../utils/ExtractDefaultChatConfig";
import { ActionCard } from "./ActionCard";
import { WorkflowAction } from "../../../../models/WorkflowAction";

export const SidebarActionDetail: React.FC<{
  data: ActionNodeData;
  updateNode: (newData: Partial<ActionNodeData>) => void;
  team: Team;
  version: WorkflowVersion;
  templates: ChatConfigurationTemplate[];
}> = ({ data, updateNode, templates, team }) => {
  const { setEdges } = useReactFlow();

  const handleSelectedProvider = (providerId: string) => {
    const template = templates.filter((t) => t.id == providerId)[0];
    const defaultConfig = extractDefaultConfig(template);
    updateNode({
      providerId: providerId,
      providerConfig: defaultConfig,
    });
  };

  const addNewAction = () => {
    const newAction: WorkflowAction = {
      id: `action_${Date.now()}`,
      name: "",
      condition: "",
      sensitivity: 80,
    };

    updateNode({
      actions: [...data.actions, newAction],
    });
  };

  const handleActionUpdate = (updatedAction: WorkflowAction) => {
    updateNode({
      actions: data.actions.map((action) =>
        action.id === updatedAction.id ? updatedAction : action
      ),
    });
  };

  const handleActionDelete = (actionId: string) => {
    // First find the index of the action being deleted
    const actionIndex = data.actions.findIndex(
      (action) => action.id === actionId
    );

    // Delete any edges connected to this action's handle
    setEdges((edges) =>
      edges.filter((edge) => {
        const handleId = `${data.id}_ACTION:${actionId}_INDEX:${actionIndex}_source`;
        return edge.sourceHandle !== handleId;
      })
    );

    // Update the node's actions array
    updateNode({
      actions: data.actions.filter((action) => action.id !== actionId),
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <CommonInputSegment
        title="Action Step Title"
        onChange={(t) => updateNode({ title: t })}
        value={data.title}
        placeholder="Step Title"
        error={undefined}
        setError={undefined}
        id="beamTitle"
        className="font-sans text-gray-700"
      />

      <div className="flex flex-col gap-2">
        <AIProviderList
          clickedProvider={handleSelectedProvider}
          selectedProvider={data.providerId ?? ""}
          providers={templates}
          showProviderConfig={() => console.log("")}
          configuredProviders={Object.keys(team.secretsUsed ?? {})}
          hideConfigure={true}
          classNameIn="font-sans text-gray-700"
        />
        <div className="flex flex-row justify-between items-center">
          <div className="text-sm font-medium text-gray-700">Actions</div>
          <button
            onClick={addNewAction}
            className="p-2 text-blue-500 hover:bg-blue-50 rounded-md"
          >
            <Icon type="plus" className="size-6" />
          </button>
        </div>
        {data.actions.map((action) => (
          <ActionCard
            key={action.id}
            action={action}
            onUpdate={handleActionUpdate}
            onDelete={handleActionDelete}
          />
        ))}{" "}
      </div>
    </div>
  );
};
