import { ConversationalRequirement } from "../../../../models/WorkflowAction";
import { CommonInputSegment } from "../../../Common/CommonInput";
import Icon from "../../../Icon";

interface RequirementCardProps {
  requirement: ConversationalRequirement;
  onUpdate: (updates: Partial<ConversationalRequirement>) => void;
  onDelete: () => void;
}

export const ConversationalRequirementCard: React.FC<RequirementCardProps> = ({
  requirement,
  onUpdate,
  onDelete,
}) => {
  return (
    <div className="mt-2 p-3 border-gray-200 border rounded-md">
      <div className="flex flex-col gap-2">
        <div className="flex justify-end items-center">
          <button
            onClick={onDelete}
            className="p-1 text-gray-400 hover:text-gray-600"
          >
            <Icon type="x" className="size-4" />
          </button>
        </div>
        <CommonInputSegment
          title="Name"
          onChange={(name) => onUpdate({ name })}
          value={requirement.name}
          placeholder="Requirement name"
          error={undefined}
          setError={undefined}
          id={`req_name_${requirement.id}`}
          className="font-sans text-gray-700 bg-transparent"
        />

        <CommonInputSegment
          title="Description"
          onChange={(description) => onUpdate({ description })}
          value={requirement.description}
          placeholder="Describe what information is needed"
          error={undefined}
          setError={undefined}
          id={`req_desc_${requirement.id}`}
          className="font-sans text-gray-700"
        />
      </div>
    </div>
  );
};
