import React, { useEffect, useState } from "react";
import CommonHeader from "../Common/CommonHeader";
import { useParams } from "react-router-dom";
import CommonContainer from "../Common/CommonContainer";
import { TeamSettingsSections } from "./TeamSettingsSections";
import { CommonCard } from "../Common/CommonCard";
import Icon, { IconType } from "../Icon";
import { Team } from "../../models/Team";
import { TeamSubscription } from "../../models/TeamSubscription";
import { useTeams } from "../../contexts/TeamContext";
import { CommonSpinner } from "../Common/CommonLoading";
import FailureModal from "../FailureModal";
import {
  PaymentOption,
  SubscriptionOption,
} from "../../models/SubscriptionOption";
import { Portal } from "../../models/Portal";
import { usePortalService } from "../../contexts/PortalContext";
import { usePortalLogService } from "../../contexts/PortalLogContext";
import { formatDate, getDaysUntil } from "../../utils/DateUtils";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import { TeamSettingsBillingUpgradeModal } from "./TeamSettingsBillingUpgradeModal";
import { TeamSettingsBillingUpgradeLoading } from "./TeamSettingsBillingUpgradeLoading";
import { useAuth } from "../../contexts/AuthContext";
import { useWorkflowService } from "../../contexts/WorkflowContext";
import { Workflow } from "../../models/Workflow";
import { filterExamplePortals } from "../../utils/FilterUtils";
import { AppPath } from "../../models/AppPath";

export const TeamSettingsBilling: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>();

  const teamService = useTeams();
  const portalService = usePortalService();
  const logService = usePortalLogService();
  const authService = useAuth();
  const workflowService = useWorkflowService();

  const [team, setTeam] = useState<Team>();
  const [teamSubscription, setTeamSubscription] = useState<TeamSubscription>();
  const [subscriptionOptions, setSubscriptionOptions] =
    useState<SubscriptionOption[]>();
  const [portals, setPortals] = useState<Portal[]>([]);
  const [requestCount, setRequestCount] = useState(0);
  const [workflows, setWorkflows] = useState<Workflow[]>([]);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [managePlanState, setManagePlanState] =
    useState<AnimationState>("ready");
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [upgradeLoading, setUpgradeLoading] = useState(false);

  const currentEvent = teamSubscription?.currentEvent;
  const currentOption = subscriptionOptions?.find(
    (s) => s.id == currentEvent?.productId
  );

  const upgradeOption = subscriptionOptions?.find(
    (s) => s.id == currentOption?.upgradeProductId
  );

  const needsCanceledWarning =
    currentEvent?.willRenew == false || currentEvent?.status == "canceled";

  const loadData = async () => {
    const logQueryStart = new Date(
      new Date().setDate(new Date().getDate() - 30)
    );
    try {
      const [
        fetchedTeam,
        fetchedSubscription,
        fetchedSubscriptionOptions,
        fetchedPortals,
        fetchedRequestCount,
        fetchedWorkflows,
      ] = await Promise.all([
        teamService.teamRepo.get(teamService.teamPath(), teamId!),
        teamService.teamSubscriptionRepo.get(
          teamService.teamSubscriptionPath(),
          teamId!
        ),
        teamService.subscriptionOptionRepo.getList(
          teamService.subscriptionOptionPath()
        ),
        portalService.portalRepo.getList(portalService.portalPath(teamId!)),
        logService.portalLogRepo.count(logService.portalLogPath(teamId!), [
          { key: "createdAt", filter: ">", value: logQueryStart },
        ]),
        workflowService.workflowRepo.getList(
          workflowService.workflowPath(teamId!)
        ),
      ]);
      if (!fetchedTeam || !fetchedSubscription || !fetchedSubscriptionOptions) {
        throw Error("There's no team data!");
      }
      const portals = fetchedPortals.filter((p) =>
        filterExamplePortals(team, p)
      );

      setTeam(fetchedTeam!);
      setTeamSubscription(fetchedSubscription!);
      setSubscriptionOptions(fetchedSubscriptionOptions);
      setPortals(portals);
      setRequestCount(fetchedRequestCount);
      setWorkflows(fetchedWorkflows);
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      } else {
        setError("Something went horribly wrong");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [teamService]);

  const calculateYearlySavings = (): number | undefined => {
    if (!currentEvent || currentEvent.interval == "year") {
      return undefined;
    }
    if (!currentOption) {
      return undefined;
    }

    const yearlyPrice = currentOption.paymentOptions.filter(
      (p) => p.interval == "year"
    )[0];
    if (!yearlyPrice) {
      return undefined;
    }

    return currentEvent.priceUSD * 12 - Number(yearlyPrice.price);
  };

  const calculateYearlyPrice = (): number => {
    if (!currentEvent) {
      return 0;
    }
    if (currentEvent.interval == "year") {
      return currentEvent.priceUSD;
    } else {
      return currentEvent.priceUSD * 12;
    }
  };

  const calculateMonthlyPrice = (): number => {
    if (!currentEvent) {
      return 0;
    }
    if (currentEvent.interval == "month") {
      return currentEvent.priceUSD;
    } else {
      return currentEvent.priceUSD / 12;
    }
  };

  const yearlySavings = calculateYearlySavings();

  const seatsUsed = Object.keys(team?.members ?? {}).length;
  const maxSeats = currentEvent?.seats ?? 1;

  const portalsUsed = portals.length;
  const maxPortals = currentEvent?.portals ?? 1;

  const workflowsUsed = workflows.length;
  const maxWorkflows = currentEvent?.workflows ?? 1;

  let statusIcon: IconType = "alert";
  let statusTitle = "Unknown";
  let statusColor = "bg-gray-50 text-grau-500";
  if (currentEvent?.status == "trialing" && currentEvent?.trialEnd) {
    statusIcon = "clock";
    statusTitle = `Free Trail: ${getDaysUntil(currentEvent?.trialEnd)}`;
    statusColor = "bg-pink-50 text-pink-500";
  } else if (needsCanceledWarning) {
    statusIcon = "alert";
    statusTitle = "Canceled";
    statusColor = "bg-red-50 text-red-500";
  } else if (currentEvent?.status == "past_due") {
    statusIcon = "alert";
    statusTitle = "Past Due";
    statusColor = "bg-red-50 text-red-500";
  } else if (currentEvent?.status == "paused") {
    statusIcon = "alert";
    statusTitle = "Paused";
    statusColor = "bg-red-50 text-red-500";
  } else if (currentEvent?.status == "active") {
    statusIcon = "check";
    statusTitle = "Active";
    statusColor = "bg-green-50 text-green-500";
  }
  const handleManagePlan = async () => {
    setManagePlanState("loading");
    try {
      if (currentEvent?.status == "canceled") {
        setShowUpgradeModal(true);
      } else {
        const url = await teamService.updateSubscription(
          teamId!,
          window.location.href
        );
        window.location.href = url;
      }
    } catch (e) {
      setError(e instanceof Error ? e.message : "Something went wrong");
    }
    setManagePlanState("ready");
  };

  const handleSelected = async (
    _: SubscriptionOption,
    price: PaymentOption,
    enterprise: boolean
  ) => {
    setShowUpgradeModal(false);
    if (currentEvent?.status == "canceled") {
      const user = await authService.currentUser();
      setLoading(true);
      const url = await teamService.purchase(
        price.priceId,
        teamId!,
        window.location.origin,
        user!.email!
      );
      window.location.href = url;

      return;
    }
    if (enterprise) {
      window.location.href = "mailto:hello@easybeam.ai";
      return;
    }

    if (price.priceId == teamSubscription?.currentEvent?.priceId) {
      return;
    }

    setUpgradeLoading(true);
    try {
      await teamService.updateStripeSubscription(teamId!, price.priceId);
      await new Promise((resolve) => setTimeout(resolve, 5000)); // delay for webhook to sync data
      await loadData();
    } catch (e) {
      setError(e instanceof Error ? e.message : "Something went wrong");
    } finally {
      setUpgradeLoading(false);
    }
  };

  return (
    <CommonContainer>
      <CommonHeader
        title={`Billing`}
        subtitle="View your subscription and make upgrades to your account here."
        sections={[
          { name: "Team Settings", link: AppPath.settings(teamId ?? "") },
          { name: "Billing", link: AppPath.billing(teamId ?? "") },
        ]}
        teamId={teamId!}
        actions={[
          <AnimatedButton
            key="manageButton"
            style="action"
            setButtonState={setManagePlanState}
            buttonState={managePlanState}
            title={`${needsCanceledWarning ? "Renew Plan" : "Manage Plan"}`}
            onClick={handleManagePlan}
          />,
        ]}
      />
      <FailureModal
        shows={error != ""}
        message={error}
        closed={() => setError("")}
      />
      {subscriptionOptions && teamSubscription && (
        <TeamSettingsBillingUpgradeModal
          isOpen={showUpgradeModal}
          subscriptions={subscriptionOptions}
          currentSubscription={teamSubscription}
          onCancel={() => setShowUpgradeModal(false)}
          handleSelected={handleSelected}
          isRenewal={currentEvent?.status == "canceled"}
        />
      )}
      <TeamSettingsBillingUpgradeLoading shows={upgradeLoading} />

      <TeamSettingsSections selectedId="billing" />
      {loading ? (
        <CommonSpinner />
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 pt-6">
          <div className="flex flex-col gap-2">
            <div className="flex flex-row justify-between">
              <div className="text-xl text-gray-700 font-gooper font-semibold">
                Your Subscription
              </div>
              <div
                className={`self-stretch py-1 px-2 ${statusColor} rounded justify-center items-center gap-1 flex`}
              >
                <Icon type={statusIcon} className="size-3" />
                <div className="text-center text-xs font-medium leading-none">
                  {statusTitle}
                </div>
              </div>
            </div>
            <span className="text-gray-500 text-sm">{`For your convenience and security, we partner with Stripe, a leading payment processor.`}</span>
            <span className="text-gray-500 text-sm">{`Stripe ensures that all your payment information is handled with the highest level of security and compliance.`}</span>
            <span className="text-gray-500 text-sm">{`To update your payment details or view your transaction history, please visit your Stripe account.`}</span>
            <a
              href="https://www.easybeam.ai/plans" // navigate to manage?
              className={`underline text-sm text-blue-400 hover:text-blue-700`}
            >
              {`Read more about plans, limits and features here.`}
            </a>
          </div>
          <CommonCard
            className={`${
              currentEvent?.willRenew == false ? "border-red-500 border-2" : ""
            }`}
          >
            {yearlySavings && (
              <div
                onClick={() => setShowUpgradeModal(true)}
                className="bg-pink-50 cursor-pointer rounded-lg flex flex-row py-1 w-full justify-center items-center gap-1 text-pink-900 text-xs font-medium"
              >
                <Icon type="info" className="size-3 " />
                You could save ${yearlySavings} USD by switching to yearly
              </div>
            )}

            <div className="flex flex-col gap-1">
              <div className="flex flex-row justify-between items-center">
                <div className={` text-sm text-gray-500`}>CURRENT PLAN</div>
                <div
                  className={`self-stretch py-1 px-2 text-gray-500 bg-gray-50 rounded justify-center items-center gap-1 flex`}
                >
                  <Icon type={"clock"} className="size-3" />
                  <div className="text-center text-xs font-medium leading-none">
                    {currentEvent?.interval == "year" ? "Yearly" : "Monthly"}
                  </div>
                </div>
              </div>

              <div
                className={`text-transparent bg-clip-text bg-gradient-to-br from-green-500 via-blue-400 to-blue-400 text-3xl font-medium font-gooper`}
              >
                {`${currentEvent?.name} Plan`}
              </div>
            </div>
            <div className={` h-1 w-full border-t border-gray-200`} />
            <UsageIndicator
              title="Prompts"
              max={maxPortals}
              usage={portalsUsed}
              color="bg-fuchsia-300"
            />
            <UsageIndicator
              title="Agents"
              max={maxWorkflows}
              usage={workflowsUsed}
              color="bg-lime-300"
            />
            <UsageIndicator
              title="Seats"
              max={maxSeats}
              usage={seatsUsed}
              color="bg-orange-500"
            />
            <UsageIndicator
              title="Requests (30-day)"
              max={(currentEvent?.dailyRequests ?? 0) * 30}
              usage={requestCount}
              color="bg-teal-500"
            />
            <div className={` h-1 w-full border-t border-gray-200`} />

            <div className="flex flex-col gap-1.5">
              <div className="flex flex-row justify-between">
                <span className="text-gray-600 text-sm">{`Team created on`}</span>
                <span className="text-gray-600 font-medium font-gooper text-sm">{`${formatDate(
                  team?.createdAt
                )}`}</span>
              </div>
              {currentEvent?.trialEnd && (
                <div className="flex flex-row justify-between">
                  <span className="text-gray-600 text-sm">{`Free trial ends on`}</span>
                  <span className="text-gray-600 font-medium font-gooper text-sm">{`${formatDate(
                    currentEvent?.trialEnd
                  )}`}</span>
                </div>
              )}
              {currentEvent?.currentPeriodEnd &&
                currentEvent?.willRenew == false && (
                  <div className="flex flex-row justify-between">
                    <span className="text-red-500 font-medium text-sm">{`SERVICE ENDS ON `}</span>
                    <span className="text-red-500 font-medium font-gooper text-sm">{`${formatDate(
                      currentEvent?.currentPeriodEnd
                    )}`}</span>
                  </div>
                )}
            </div>
            <div className={` h-1 w-full border-t border-gray-200`} />
            <div className="flex flex-col gap-1">
              {currentEvent?.interval == "year" ? (
                <div>
                  <span className="text-gray-600 text-sm">{`billed`}</span>
                  <span className="text-gray-600 px-1 text-sm font-medium font-gooper">{` ${calculateYearlyPrice().toFixed(
                    2
                  )} USD  `}</span>
                  <span className="text-gray-600 text-sm">{`per year`}</span>
                </div>
              ) : (
                <div>
                  <span className="text-gray-600 text-sm">{`billed`}</span>
                  <span className="text-gray-600 px-1 text-sm font-medium font-gooper">{` ${calculateMonthlyPrice().toFixed(
                    2
                  )} USD  `}</span>
                  <span className="text-gray-600 text-sm">{`per month`}</span>
                </div>
              )}
              {yearlySavings && (
                <div className="flex flex-row justify-end">
                  <span
                    onClick={() => setShowUpgradeModal(true)}
                    className={`cursor-pointer underline font-gooper text-sm font-medium text-blue-400 hover:text-blue-700`}
                  >
                    {`Switch to a yearly plan and save ${(
                      (yearlySavings / calculateYearlyPrice()) *
                      100
                    ).toFixed(2)}%`}
                  </span>
                </div>
              )}
            </div>
          </CommonCard>
          <div className="flex flex-col gap-8">
            {currentOption?.upsellFeatureList && (
              <FeatureList
                title="Your Plan includes"
                iconColor="bg-blue-50 text-blue-500"
                listString={currentOption?.upsellFeatureList}
              />
            )}
            {upgradeOption?.upsellFeatureList && (
              <FeatureList
                title="Unlock more powerful features"
                iconColor="bg-green-50 text-green-500"
                listString={upgradeOption?.upsellFeatureList}
              />
            )}
            <div className="flex justify-end">
              {currentEvent?.status != "canceled" && (
                <button
                  className="flex flex-row items-center gap-2 group"
                  onClick={() => setShowUpgradeModal(true)}
                >
                  <Icon
                    type="eb-logo"
                    className="bg-gradient-to-br from-green-500 via-blue-400 to-blue-400 size-4 rounded-full p-0.5"
                  />
                  <div className="font-gooper text-blue-500 group-hover:text-blue-700">
                    Upgrade plan
                  </div>
                </button>
              )}{" "}
            </div>
          </div>
        </div>
      )}
    </CommonContainer>
  );
};

const UsageIndicator: React.FC<{
  title: string;
  usage: number;
  max: number;
  color: string;
}> = ({ title, usage, max, color }) => {
  let percentage = usage / max;
  if (percentage > 1) {
    percentage = 1;
  }

  return (
    <div className="w-full gap-2 flex flex-col bg-gray-0 rounded-lg">
      <div className="flex flex-row justify-between">
        <div className="text-gray-600 text-sm">{title}</div>
        <div className="text-gray-600 font-medium font-gooper text-sm">{`${usage}/${max}`}</div>
      </div>
      <div className="flex-1 flex-grow">
        <div className="h-1 w-full rounded-sm bg-gray-200">
          <div
            style={{ width: `${percentage * 100}%` }}
            className={`h-full rounded-sm ${color}`}
          ></div>
        </div>
      </div>
    </div>
  );
};

const FeatureList: React.FC<{
  title: string;
  listString: string;
  iconColor: string;
}> = ({ title, listString, iconColor }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row justify-between">
        <div className="text-xl text-gray-700 font-gooper font-semibold">
          {title}
        </div>
      </div>
      <div className="flex flex-wrap gap-2">
        {listString.split(",").map((feature, index) => {
          return (
            <span
              key={index}
              className="flex flex-row items-center gap-0.5 pt-1"
            >
              <Icon
                type="check-line"
                className={`size-4 rounded-full ${iconColor}`}
              />
              <span className="text-gray-600 text-sm font-medium">{` ${feature}`}</span>
            </span>
          );
        })}{" "}
      </div>
    </div>
  );
};
