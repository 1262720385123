export const ExternalPath = {
  docs(): string {
    return "https://docs.easybeam.ai";
  },
  promptDocs(): string {
    return `${this.docs()}/features/prompts`;
  },
  agentDocs(): string {
    return "https://docs.easybeam.ai/features/agents";
  },
  api(): string {
    return "https://docs.easybeam.ai/api";
  },
  tos(): string {
    return "https://easybeam.ai/privacy";
  },
  pp(): string {
    return "https://easybeam.ai/terms";
  },
  blog(): string {
    return "https://easybeam.ai/explained";
  },
};
