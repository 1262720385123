import { Edge, Node } from "reactflow";
import {
  WorkflowSteps,
  WorkflowStep,
  WorkflowPortalStep,
  WorkflowVariableInputs,
  WorkflowDecisionStep,
  WorkflowAPIStep,
  WorkflowVersion,
  WorkflowActionStep,
  WorkflowWebsiteStep,
} from "../models/Workflow";
import {
  PortalNodeData,
  PortalNodeType,
  SelectedInputs,
} from "../components/Workflows/Map/Nodes/PortalNode";
import { StartNodeType } from "../components/Workflows/Map/Nodes/StartNode";
import {
  DecisionNodeData,
  DecisionNodeType,
  falseNodeHandlerId,
  trueNodeHandlerId,
} from "../components/Workflows/Map/Nodes/DecisionNode";
import {
  APINodeData,
  APINodeType,
} from "../components/Workflows/Map/Nodes/APINode";
import {
  ActionNodeData,
  ActionNodeType,
} from "../components/Workflows/Map/Nodes/ActionNode";
import { Team } from "../models/Team";
import { SelectedInput } from "../components/Workflows/Sidebar/VariableInputSelector";
import {
  WebsiteNodeData,
  WebsiteNodeType,
} from "../components/Workflows/Map/Nodes/WebsiteNode";

export interface WorkflowConverter {
  getSteps(
    nodes: Node[],
    edges: Edge[],
    team: Team,
    isDemo: boolean,
    version: WorkflowVersion
  ): ConvertedWorkflow | WorkflowConversionIssues;
}

export type ConvertedWorkflow = {
  type: "conversion";
  steps: WorkflowSteps;
  firstStepId: string;
  startNodeId: string;
};

export type WorkflowConversionIssues = {
  type: "issue";
  problems: WorkflowConversionProblem[];
};

export interface WorkflowConversionProblem {
  nodeId: string;
  message: string;
  id: string;
}

interface InputConversionResult {
  type: "success";
  inputs: WorkflowVariableInputs;
}

interface WorkflowStepConversionResult {
  step: WorkflowStep;
  type: "success";
}

export class ReactFlowWorkflowConverter implements WorkflowConverter {
  getSteps(
    nodes: Node[],
    edges: Edge[],
    team: Team,
    isDemo: boolean
  ): ConvertedWorkflow | WorkflowConversionIssues {
    const steps: WorkflowSteps = {};
    let firstStepId = this.getFirstId(edges);
    let problems: WorkflowConversionProblem[] = [];

    for (const node of nodes) {
      if (
        node.type == StartNodeType ||
        !this.isConnectedToStart(node.id, nodes, edges)
      ) {
        continue;
      }

      const result = this.convertNodeToStep(node, nodes, edges, team, isDemo);
      if (result?.type == "issue") {
        problems = problems.concat(result.problems);
        continue;
      }
      if (!result) {
        continue;
      }

      steps[result.step.id] = result.step;
    }

    if (!firstStepId) {
      problems.push(
        this.generateProblem(StartNodeType, "Attach something to the start!")
      );
      return { type: "issue", problems: problems };
    }

    if (problems.length > 0) {
      return { type: "issue", problems: problems };
    }

    return {
      steps,
      firstStepId,
      type: "conversion",
      startNodeId: StartNodeType,
    };
  }

  convertNodeToStep(
    node: Node,
    nodes: Node[],
    edges: Edge[],
    team: Team,
    isDemo: boolean
  ): WorkflowStepConversionResult | WorkflowConversionIssues | undefined {
    let problems: WorkflowConversionProblem[] = [];

    if (node.type == PortalNodeType) {
      const data = node.data as PortalNodeData;

      if (!data.currentVersion) {
        problems.push(this.generateProblem(node.id, "Needs a version"));
      }

      const nextStep = edges.filter((edge) => edge.source === node.id)[0]
        ?.target;

      const inputs = this.convertVariableInputs(
        data.selectedInputs,
        node.id,
        data.title,
        nodes
      );

      if (
        !isDemo &&
        (team.secretsUsed ?? {})[data.currentVersion!.configId] != true
      ) {
        problems.push(
          this.generateProblem(
            node.id,
            `Needs keys for ${data.currentVersion!.configId}`
          )
        );
      }
      if (inputs.type == "issue") {
        problems = problems.concat(inputs.problems);
        return { type: "issue", problems };
      } else if (problems.length > 0) {
        return { type: "issue", problems };
      }

      const portalStep: WorkflowPortalStep = {
        id: node.id,
        stepType: "portal",
        version: data.currentVersion!,
        versionId: data.currentVersion!.id!,
        nextStepId: nextStep,
        inputs: inputs.inputs,
        includeMessages: data.includeMessages != false,
        name: data.title,
      };
      return {
        type: "success",
        step: portalStep,
      };
    } else if (node.type == DecisionNodeType) {
      const data = node.data as DecisionNodeData;

      const inputs = this.convertVariableInputs(
        { input: data.selectedInput, operand: data.selectedOperand },
        node.id,
        data.title,
        nodes
      );

      if (!this.inputConfigured(data.selectedOperand)) {
        problems.push(
          this.generateProblem(
            node.id,
            `${data.title} needs something to compare`
          )
        );
      }

      if (!data.selectedOperator) {
        problems.push(
          this.generateProblem(
            node.id,
            `${data.title} needs a logical operator`
          )
        );
      }

      if (!this.inputConfigured(data.selectedInput)) {
        problems.push(
          this.generateProblem(
            node.id,
            `${data.title} needs something to compare against`
          )
        );
      }

      const trueStepId = edges.filter(
        (e) => e.sourceHandle == trueNodeHandlerId(node.id)
      )[0]?.target;
      if (!trueStepId) {
        problems.push(
          this.generateProblem(
            node.id,
            `${data.title} needs a true step connected`
          )
        );
      }

      const falseStepId = edges.filter(
        (e) => e.sourceHandle == falseNodeHandlerId(node.id)
      )[0]?.target;

      if (!falseStepId) {
        problems.push(
          this.generateProblem(
            node.id,
            `${data.title} needs a false step connected`
          )
        );
      }

      if (inputs.type == "issue") {
        problems = problems.concat(inputs.problems);
        return { type: "issue", problems };
      } else if (problems.length > 0) {
        return { type: "issue", problems };
      }

      const decisionStep: WorkflowDecisionStep = {
        stepType: "decision",
        id: node.id,
        input: inputs.inputs.input,
        operand: inputs.inputs.operand,
        operator: data.selectedOperator!,
        trueStepId,
        falseStepId,
        name: data.title,
      };

      return {
        type: "success",
        step: decisionStep,
      };
    } else if (node.type == APINodeType) {
      const data = node.data as APINodeData;

      const nextStepId =
        edges.find((edge) => edge.source === node.id)?.target || null;
      const isInActionBranch = this.isConnectedToActionHandler(
        node.id,
        nodes,
        edges
      );

      if (!nextStepId && !isInActionBranch) {
        problems.push(
          this.generateProblem(
            node.id,
            `API step "${data.title}" has no next step.`
          )
        );
      }

      if (!data.url) {
        problems.push(
          this.generateProblem(
            node.id,
            `API step "${data.title}" is missing a URL.`
          )
        );
      }

      if (problems.length > 0) {
        return { type: "issue", problems };
      }

      const apiStep: WorkflowAPIStep = {
        stepType: "api",
        id: node.id,
        method: data.method,
        url: data.url,
        headers: data.headers,
        body: data.body,
        nextStepId,
        name: data.title,
      };

      return { type: "success", step: apiStep };
    } else if (node.type === ActionNodeType) {
      const data = node.data as ActionNodeData;

      // Get the default next step from the bottom handle
      const defaultNextStepId =
        edges.find(
          (edge) =>
            edge.source === node.id && edge.sourceHandle === `${node.id}_source`
        )?.target || null;

      // Get all action-specific edges
      const actionEdges = data.actions.map((action) => {
        const actionHandle = edges.find(
          (edge) =>
            edge.source === node.id &&
            edge.sourceHandle?.startsWith(`${node.id}_ACTION:${action.id}`)
        );

        if (actionHandle) {
          // Validate that the target isn't a decision node when connected to an action handle
          const targetNode = nodes.find((n) => n.id === actionHandle.target);
          if (targetNode?.type === DecisionNodeType) {
            problems.push(
              this.generateProblem(
                node.id,
                `Action "${action.name}" cannot connect to a decision node`
              )
            );
          }
        }

        return {
          id: action.id,
          name: action.name,
          condition: action.condition,
          nextStepId: actionHandle?.target || null,
          sensitivity: action.sensitivity,
          conversationalRequirements:
            action.conversationalRequirements?.map((req) => ({
              id: req.id,
              name: req.name,
              description: req.description,
            })) ?? [],
        };
      });

      if (!data.actions.length) {
        problems.push(
          this.generateProblem(
            node.id,
            `Action node "${data.title}" needs at least one action`
          )
        );
      }

      if (!this.targetConnected(node.id, edges)) {
        problems.push(
          this.generateProblem(
            node.id,
            `Action node "${data.title}" needs an incoming connection`
          )
        );
      }

      if (!isDemo && (team.secretsUsed ?? {})[data!.providerId] != true) {
        problems.push(
          this.generateProblem(node.id, `Needs keys for ${data!.providerId}`)
        );
      }

      if (problems.length > 0) {
        return { type: "issue", problems };
      }

      const actionStep: WorkflowActionStep = {
        stepType: "action",
        id: node.id,
        name: data.title,
        actions: actionEdges,
        nextStepId: defaultNextStepId,
        aiProviderId: data.providerId,
        aiProviderConfig: data.providerConfig,
      };

      return {
        type: "success",
        step: actionStep,
      };
    } else if (node.type === WebsiteNodeType) {
      const data = node.data as WebsiteNodeData;

      const nextStepId =
        edges.find((edge) => edge.source === node.id)?.target || null;
      const isInActionBranch = this.isConnectedToActionHandler(
        node.id,
        nodes,
        edges
      );

      if (!nextStepId && !isInActionBranch) {
        problems.push(
          this.generateProblem(
            node.id,
            `Website step "${data.title}" has no next step.`
          )
        );
      }

      if (!data.url) {
        problems.push(
          this.generateProblem(
            node.id,
            `Website step "${data.title}" is missing a URL.`
          )
        );
      }

      if (problems.length > 0) {
        return { type: "issue", problems };
      }

      const websiteStep: WorkflowWebsiteStep = {
        stepType: "website",
        id: node.id,
        name: data.title,
        url: data.url,
        nextStepId,
        filterOptions: {
          removeScripts: data.filterOptions.removeScripts,
          removeStyles: data.filterOptions.removeStyles,
          removeNavigation: data.filterOptions.removeNavigation,
          removeFooter: data.filterOptions.removeFooter,
          removeAds: data.filterOptions.removeAds,
          keepMainContent: data.filterOptions.keepMainContent,
          customSelectors: data.filterOptions.customSelectors,
        },
      };

      return { type: "success", step: websiteStep };
    }

    return undefined;
  }

  inputConfigured(input: SelectedInput | undefined): boolean {
    if (input == undefined) {
      return false;
    }
    if (input.type == "output") {
      return !!input.nodeId;
    } else if (input.type == "variable") {
      return !!input.variableId;
    } else if (input.type == "conversational") {
      return !!input.requirementId;
    } else {
      return input.value != undefined;
    }
  }

  convertVariableInputs(
    selectedInputs: SelectedInputs | undefined,
    nodeId: string,
    title: string,
    nodes: Node[] // Add nodes parameter to check for existence
  ): InputConversionResult | WorkflowConversionIssues {
    const inputs: WorkflowVariableInputs = {};
    const problems: WorkflowConversionProblem[] = [];
    Object.keys(selectedInputs ?? {}).map((v) => {
      const value = (selectedInputs ?? {})[v];

      if (value?.type == "text") {
        inputs[v] = { inputType: "static", value: value.value ?? "" };
      } else if (value?.type == "variable") {
        if (!value.variableId) {
          problems.push(
            this.generateProblem(nodeId, `Missing variable input in ${title}`)
          );
        }
        inputs[v] = { inputType: "variable", value: value.variableId! };
      } else if (value?.type == "output") {
        if (!value.nodeId) {
          problems.push(
            this.generateProblem(
              nodeId,
              `Missing step output as input for in ${title}`
            )
          );
        } else {
          // Check if the referenced node exists
          const referencedNode = nodes.find((node) => node.id === value.nodeId);
          if (!referencedNode) {
            problems.push(
              this.generateProblem(
                nodeId,
                `Referenced deleted step in ${title}`
              )
            );
          }
        }
        inputs[v] = { inputType: "output", value: value.nodeId! };
      } else if (value?.type == "conversational") {
        if (!value.requirementId) {
          problems.push(
            this.generateProblem(
              nodeId,
              `Missing conversation data as input for in ${title}`
            )
          );
        } else {
          // Check if the referenced node exists
          const referencedNode = nodes.find((node) => node.id === value.nodeId);
          if (!referencedNode) {
            problems.push(
              this.generateProblem(
                nodeId,
                `Referenced deleted step in ${title}`
              )
            );
          }
        }
        inputs[v] = {
          inputType: "conversational",
          value: value.requirementId!,
        };
      } else if (value == undefined) {
        problems.push(
          this.generateProblem(nodeId, `Missing variable input for in ${title}`)
        );
      }
    });
    if (problems.length != 0) {
      return { type: "issue", problems: problems };
    }
    return { type: "success", inputs };
  }

  getFirstId(edges: Edge[]): string | undefined {
    return edges.filter((e) => e.source == StartNodeType)[0]?.target;
  }

  isConnectedToStart(
    nodeId: string,
    nodes: Node[],
    edges: Edge[],
    visited: Set<string> = new Set()
  ): boolean {
    if (nodeId === StartNodeType) {
      return true;
    }

    if (visited.has(nodeId)) {
      return false;
    }

    visited.add(nodeId);

    const incomingEdges = edges.filter((edge) => edge.target === nodeId);

    for (const edge of incomingEdges) {
      if (this.isConnectedToStart(edge.source, nodes, edges, visited)) {
        return true;
      }
    }

    return false;
  }

  private targetConnected(nodeId: string, edges: Edge[]): boolean {
    return edges.some((edge) => edge.target === nodeId);
  }

  isConnectedToActionHandler(
    nodeId: string,
    nodes: Node[],
    edges: Edge[],
    visited: Set<string> = new Set()
  ): boolean {
    // Prevent infinite loops in cyclic graphs
    if (visited.has(nodeId)) {
      return false;
    }
    visited.add(nodeId);

    // Get all incoming edges to this node
    const incomingEdges = edges.filter((edge) => edge.target === nodeId);

    for (const edge of incomingEdges) {
      const sourceNode = nodes.find((node) => node.id === edge.source);

      // If the source is an action node, check if the connection is from an action handle
      if (sourceNode?.type === ActionNodeType) {
        const sourceHandle = edge.sourceHandle;
        if (sourceHandle && sourceHandle.includes("_ACTION:")) {
          return true;
        }
      }

      // Recursively check the parent nodes
      if (this.isConnectedToActionHandler(edge.source, nodes, edges, visited)) {
        return true;
      }
    }

    return false;
  }

  generateProblem(nodeId: string, message: string): WorkflowConversionProblem {
    const timestamp = Date.now().toString(36);
    const randomPart = Math.random().toString(36).substring(2, 15);
    return {
      nodeId,
      message,
      id: `${timestamp}-${randomPart}`,
    };
  }
}
