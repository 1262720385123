import { useNavigate } from "react-router-dom";
import { Portal } from "../../../models/Portal";
import { PortalVersion } from "../../../models/PortalVersion";
import { useEffect, useRef, useState } from "react";
import AnimatedButton, { AnimationState } from "../../AnimatedButton";
import { usePortalService } from "../../../contexts/PortalContext";
import { IconType } from "../../Icon";
import { CommonConfirmationModal } from "../../Common/CommonConfirmationModal";
import FailureModal from "../../FailureModal";
import { AppPath } from "../../../models/AppPath";
import { APIModal } from "./APIModal";

export const PortalDetalActionMenu: React.FC<{
  teamId: string;
  portal: Portal;
  version: PortalVersion;
  updatedVersion: (version: PortalVersion) => Promise<void>;
  shows: boolean;
  setShows: (show: boolean) => void;
  isLive: boolean;
}> = ({ portal, version, updatedVersion, teamId, setShows, shows, isLive }) => {
  const navigate = useNavigate();
  const portalService = usePortalService();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [deleteVersionState, setDeleteVersionState] =
    useState<AnimationState>("ready");
  const [deletePortalState, setDeletePortalState] =
    useState<AnimationState>("ready");

  const [downgradeState, setDowngradeState] = useState<AnimationState>("ready");
  const [archiveState, setArchiveState] = useState<AnimationState>("ready");

  const [revokeModalOpen, setRevokeModalOpen] = useState(false);
  const [deleteVersionModal, setDeleteVersionModal] = useState(false);
  const [deletePortalModal, setDeletePortalModal] = useState(false);

  const [duplicateState, setDuplicateState] = useState<AnimationState>("ready");
  const [apiModal, setAPIModal] = useState(false);

  const [error, setError] = useState("");

  let downgradeTitle;
  let downgradeIcon: IconType | undefined;
  if (isLive) {
    downgradeTitle = "Take Offline";
    downgradeIcon = "pause";
  } else if (version.status === "READY") {
    downgradeTitle = "Set as Draft";
    downgradeIcon = "edit-list";
  }

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setShows(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleDelete = async () => {
    setDeleteVersionState("loading");
    try {
      await portalService.updateVersion(
        undefined,
        version.id!,
        teamId,
        portal.id!
      );
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("failed to delete");
      }
    } finally {
      setDeleteVersionModal(false);
    }
    setDeleteVersionState("success");
    navigate(AppPath.portal(teamId, portal.id), {
      replace: true,
    });
  };

  const handleDeletePortal = async () => {
    setDeletePortalState("loading");
    try {
      await portalService.deletePortal(teamId, portal.id!);
      setDeletePortalState("success");
      navigate(AppPath.portals(teamId), {
        replace: true,
      });
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("failed to delete");
      }
    } finally {
      setDeletePortalModal(false);
    }
  };

  const handleTakeOffline = async () => {
    setRevokeModalOpen(false);
    setDowngradeState("loading");
    try {
      if (isLive) {
        // Unpublish the version
        await portalService.unpublishVersion(teamId, portal.id!);
        await updatedVersion({ ...version });
      } else {
        // Downgrade to DRAFT
        await portalService.updateVersion(
          { ...version, status: "DRAFT" },
          version.id!,
          teamId,
          portal.id!
        );
        await updatedVersion({ ...version, status: "DRAFT" });
      }
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      } else {
        setError("Something went wrong!");
      }
    }
    setDowngradeState("success");
    setShows(false);
  };

  const handleArchive = async () => {
    setArchiveState("loading");
    try {
      await portalService.updateVersion(
        { ...version, status: "ARCHIVED" },
        version.id!,
        teamId,
        portal.id!
      );
      await updatedVersion({ ...version, status: "ARCHIVED" });
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      } else {
        setError("Something went wrong!");
      }
    }
    setArchiveState("success");
    setShows(false);
  };

  const handleDuplication = async () => {
    setDuplicateState("loading");
    try {
      const newVersion = await portalService.duplicateVersion(
        teamId!,
        portal.id!,
        version.id!
      );
      setDuplicateState("ready");
      navigate(AppPath.portalVersion(teamId!, portal.id!, newVersion.id!));
    } catch {
      setDuplicateState("error");
    } finally {
      setShows(false);
    }
  };

  const handleEditPortal = () => {
    navigate(AppPath.portalVersion(teamId, portal.id, version.id!, true), {
      replace: true,
    });
    setShows(false);
  };

  return (
    <div
      ref={wrapperRef}
      className={`flex flex-col gap-1 w-60 absolute top-0 z-50 shadow-lg bg-gray-0 rounded-lg transition-all duration-200 overflow-clip ${
        shows ? "" : "hidden"
      }`}
    >
      <APIModal shows={apiModal} setShows={setAPIModal} portal={portal} />

      <FailureModal
        title={"Something went wrong"}
        message={`${error}`}
        backButtonTitle={"Back to Prompt"}
        shows={error != ""}
        closed={() => setError("")}
      />
      <CommonConfirmationModal
        isOpen={revokeModalOpen}
        title="This will break the API!"
        message="This version is live, so all API traffic to this prompt will be broken until a new live version is set. Are you sure you want to do this?"
        onCancel={() => setRevokeModalOpen(false)}
        onConfirm={() => handleTakeOffline()}
        confirmStyle={"destructive"}
        confirmIcon="portals"
      />

      <CommonConfirmationModal
        isOpen={deleteVersionModal}
        title="Are you sure?"
        message="Deleting this version will be permanent. Use the archive function if you want to store your version."
        onCancel={() => setDeleteVersionModal(false)}
        onConfirm={() => handleDelete()}
        confirmStyle={"destructive"}
        confirmIcon="trash"
        confirmState={deleteVersionState}
      />

      <CommonConfirmationModal
        isOpen={deletePortalModal}
        title="Are you sure?"
        message="Deleting this prompt will be permanent, and all versions and tests will be deleted along with it!"
        onCancel={() => setDeletePortalModal(false)}
        onConfirm={() => handleDeletePortal()}
        confirmStyle={"destructive"}
        confirmIcon="trash"
        confirmState={deletePortalState}
      />

      {!isLive && (
        <AnimatedButton
          title={"Duplicate Version"}
          onClick={() => handleDuplication()}
          buttonState={duplicateState}
          setButtonState={setDuplicateState}
          style={"transparent-black"}
          key="duplicate"
          id="duplicateButton"
          leftIcon="versions"
        />
      )}
      {downgradeTitle && downgradeIcon && (
        <AnimatedButton
          title={downgradeTitle}
          onClick={() =>
            isLive ? setRevokeModalOpen(true) : handleTakeOffline()
          }
          buttonState={downgradeState}
          setButtonState={setDowngradeState}
          style={"transparent-black"}
          key="downgrade"
          leftIcon={downgradeIcon}
          id="downgradeButton"
        />
      )}

      <AnimatedButton
        title={"Edit Prompt Name"}
        onClick={() => handleEditPortal()}
        buttonState={"ready"}
        setButtonState={() => console.log("")}
        style={"transparent-black"}
        key="portal"
        leftIcon="portals"
        id="editPortal"
      />

      <AnimatedButton
        title={"Connect API"}
        onClick={() => setAPIModal(true)}
        buttonState={"ready"}
        setButtonState={() => console.log("")}
        style={"transparent-black"}
        key="portalAPI"
        leftIcon="api"
        id="portalAPI"
      />

      <AnimatedButton
        title={"Archive Version"}
        onClick={() => handleArchive()}
        buttonState={archiveState}
        setButtonState={setArchiveState}
        style={"transparent-black"}
        key="archive"
        leftIcon="archived"
        disabled={isLive || version.status === "ARCHIVED"}
        id="archiveButton"
      />
      <AnimatedButton
        title={"Delete Version"}
        onClick={() => setDeleteVersionModal(true)}
        buttonState={deleteVersionState}
        setButtonState={setDeleteVersionState}
        style={"transparent-destructive"}
        key="delete"
        leftIcon="trash"
        disabled={isLive}
        id="deleteButton"
      />
      <AnimatedButton
        title={"Delete Prompt"}
        onClick={() => setDeletePortalModal(true)}
        buttonState={deletePortalState}
        setButtonState={setDeletePortalState}
        style={"transparent-destructive"}
        key="deletePortal"
        leftIcon="alert"
        id="deletePortalButton"
      />
    </div>
  );
};
