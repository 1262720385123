import { CommonInputSegment } from "../../../Common/CommonInput";
import { useReactFlow } from "reactflow";
import { SearchNodeData } from "../../Map/Nodes/SearchNode";

export const SidebarSearchDetail: React.FC<{
  data: SearchNodeData;
}> = ({ data }) => {
  const { setNodes } = useReactFlow();

  const updateNode = (newData: Partial<SearchNodeData>) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === data.id) {
          return { ...node, data: { ...node.data, ...newData } };
        }
        return node;
      })
    );
  };

  return (
    <div className="flex flex-col gap-2">
      <CommonInputSegment
        title="Search Step Title"
        onChange={(t) => updateNode({ title: t })}
        value={data.title}
        placeholder="Step Title"
        error={undefined}
        setError={undefined}
        id="beamTitle"
        className="font-sans text-gray-700"
      />

      <div className="flex flex-col gap-1">
        <CommonInputSegment
          title="Query"
          value={data.query ?? ""}
          onChange={(v) => updateNode({ query: v })}
          placeholder="Search query"
          error={undefined}
          setError={undefined}
          id="beamTitle"
          className="font-sans text-gray-700"
        />
      </div>
    </div>
  );
};
