import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { AgentChat } from "./AgentChat";
import { ChatMessage } from "../../models/ChatMessage";
import { Easybeam } from "easybeam-react";
import { useWorkflowService } from "../../contexts/WorkflowContext";
import { SoloAgent as SoloAgentType } from "../../models/SoloAgent";
import { CommonSpinner } from "../Common/CommonLoading";
import FailureModal from "../FailureModal";

const SESSION_USER_ID_KEY = "session_user_id";

export const SoloAgent: React.FC = () => {
  const { agentId } = useParams<{ agentId: string }>();
  const navigate = useNavigate();
  const workflowService = useWorkflowService();

  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [agent, setAgent] = useState<SoloAgentType | undefined>();
  const [easybeam, setEasybeam] = useState<Easybeam | undefined>();
  const [chatId, setChatId] = useState<string>();
  const [userId] = useState<string>(() => {
    const existingUserId = sessionStorage.getItem(SESSION_USER_ID_KEY);
    if (existingUserId) {
      return existingUserId;
    }
    const newUserId = "user-" + uuidv4().slice(0, 8);
    sessionStorage.setItem(SESSION_USER_ID_KEY, newUserId);
    return newUserId;
  });

  useEffect(() => {
    const fetchAgent = async () => {
      if (!agentId) return;

      try {
        const fetchedAgent = await workflowService.agentRepo.get(
          workflowService.agentPath(),
          agentId
        );

        if (!fetchedAgent || !fetchedAgent.published) {
          throw new Error("This agent hasn't been published yet");
        }

        if (!fetchedAgent.published?.token) {
          throw new Error("Agent configuration is incomplete");
        }

        setAgent(fetchedAgent);
        setEasybeam(new Easybeam({ token: fetchedAgent.published?.token }));
      } catch (error) {
        console.error("Failed to fetch agent:", error);
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError("Failed to load agent");
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchAgent();
  }, [agentId, workflowService]);

  const sendMessage = async (messageContent: string) => {
    if (!messageContent.trim() || !agent || !easybeam) return;

    const userMessage: ChatMessage = {
      id: uuidv4(),
      role: "USER",
      content: messageContent,
      createdAt: new Date().toISOString(),
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setIsRunning(true);

    const aiMessage: ChatMessage = {
      id: uuidv4(),
      role: "AI",
      content: "",
      createdAt: new Date().toISOString(),
    };
    setMessages((prev) => [...prev, aiMessage]);

    try {
      const formattedMessages = messages.map((msg) => ({
        content: msg.content,
        role: msg.role,
        createdAt: msg.createdAt,
        id: msg.id,
      }));

      formattedMessages.push({
        content: userMessage.content,
        role: userMessage.role,
        createdAt: userMessage.createdAt,
        id: userMessage.id,
      });

      await easybeam.streamAgent(
        agentId!,
        userId,
        {},
        formattedMessages,
        (response) => {
          setChatId(response.chatId);
          setMessages((prev) => {
            const newMessages = [...prev];
            const lastMessage = newMessages[newMessages.length - 1];
            if (lastMessage && lastMessage.role === "AI") {
              lastMessage.content = response.newMessage.content;
            }
            return newMessages;
          });
        },
        () => {
          setIsRunning(false);
        },
        (error) => {
          console.error("Error in stream:", error);
          setMessages((prev) => {
            const newMessages = [...prev];
            const lastMessage = newMessages[newMessages.length - 1];
            if (lastMessage && lastMessage.role === "AI") {
              lastMessage.content =
                "Sorry, something went wrong. Please try again.";
            }
            return newMessages;
          });
          setIsRunning(false);
        }
      );
    } catch (error) {
      console.error("Failed to send message:", error);
      setMessages((prev) => {
        const newMessages = [...prev];
        const lastMessage = newMessages[newMessages.length - 1];
        if (lastMessage && lastMessage.role === "AI") {
          lastMessage.content =
            "Sorry, something went wrong. Please try again.";
        }
        return newMessages;
      });
      setIsRunning(false);
    }
  };

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <CommonSpinner />
      </div>
    );
  }

  return (
    <>
      <FailureModal
        title="Something went wrong"
        message={error}
        backButtonTitle="Back to Home"
        shows={error !== ""}
        closed={() => navigate("/home")}
      />

      {agent && easybeam && (
        <div
          className="h-screen flex flex-col"
          style={{ backgroundColor: agent.published?.themeColor }}
        >
          <div className="flex flex-grow">
            <div className="flex-grow">
              <AgentChat
                agent={agent}
                messages={messages}
                running={isRunning}
                sendMessage={sendMessage}
                chatId={chatId}
                easybeam={easybeam}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
