import { useNavigate } from "react-router-dom";
import Icon from "../Icon";
import { AppPath } from "../../models/AppPath";

export const ScoreBadge: React.FC<{
  teamId: string;
  portalId: string;
  versionId: string;
  score: number;
}> = ({ score, portalId, teamId, versionId }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() =>
        navigate(AppPath.portalVersionReviews(teamId, portalId, versionId))
      }
      className="self-stretch p-1 bg-orange-50 hover:bg-orange-100 cursor-pointer rounded justify-center items-center gap-1 flex"
    >
      <Icon type={"star"} className="size-3 text-amber-400" />
      <div className="text-center text-amber-400 text-xs font-medium leading-none">
        {`${Math.round(score * 10) / 10}`}
      </div>
    </div>
  );
};
