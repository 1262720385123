import React from "react";
import { useReactFlow } from "reactflow";
import Icon from "../../../Icon";
import { createSelectorNode } from "./SelectorNode";
import { AddNodeHandler } from "../Handlers/AddNodeHandler";

export const APINodeType = "workflowAPINodeType";

export type APIMethod = "GET" | "PATCH" | "POST" | "PUT" | "DELETE";

export interface APINodeData {
  id: string;
  teamId: string;
  type: typeof APINodeType;
  title: string;

  method: APIMethod;
  url: string;
  headers: { [key: string]: string };
  body?: string;
}

export interface APINodeParams {
  data: APINodeData;
}

export const APINode: React.FC<APINodeParams> = ({ data }) => {
  const { getNode, setNodes, getEdges } = useReactFlow();

  const handleAdd = () => {
    const currentNode = getNode(data.id);
    if (!currentNode) return;

    const selectionNode = createSelectorNode(currentNode, data.teamId);
    setNodes((nds) => nds.concat(selectionNode));
  };

  const sourceConnected = () => {
    const edges = getEdges();
    return edges.some((edge) => edge.source === data.id);
  };

  const targetConnected = () => {
    const edges = getEdges();
    return edges.some((edge) => edge.target === data.id);
  };

  const selected = getNode(data.id)?.selected;
  const nodeClasses = `shadow group box-border border-2 ${
    selected ? "border-blue-500" : "border-transparent hover:border-blue-500"
  } flex flex-col gap-1 rounded-lg p-3 bg-gray-0 w-56 transition-all`;

  return (
    <div className={nodeClasses}>
      <AddNodeHandler
        type="target"
        isConnected={targetConnected()}
        id={`${data.id}_target`}
      />
      <div className="flex flex-row justify-between h-6">
        <APIBadge method={data.method} />
      </div>
      <div className="text-2xl font-gooper text-gray-900 h-12 truncate">
        {data.title}
      </div>
      <div className="flex flex-col gap-1">
        <div className="text-sm text-gray-500 truncate">{data.url}</div>
        <div className="text-xs text-gray-400 truncate">
          {Object.keys(data.headers).length} Headers
          {data.body && " • Has Body"}
        </div>
      </div>
      <AddNodeHandler
        onClick={handleAdd}
        type="source"
        isConnected={sourceConnected()}
        id={`${data.id}_source`}
      />
    </div>
  );
};

export type APIBadgeType = APIMethod | "API";

export const APIBadge: React.FC<{ method?: APIBadgeType }> = ({ method }) => {
  const getMethodColor = (method: APIBadgeType) => {
    switch (method) {
      case "GET":
        return "bg-blue-50 text-blue-400";
      case "POST":
        return "bg-green-50 text-green-400";
      case "PATCH":
        return "bg-purple-50 text-purple-400";
      case "PUT":
        return "bg-yellow-50 text-yellow-400";
      case "DELETE":
        return "bg-red-50 text-red-400";
      case "API":
        return "bg-emerald-50 text-emerald-400";
    }
  };

  return (
    <div
      className={`px-2.5 py-0.5 rounded justify-center items-center gap-1 flex ${getMethodColor(
        method ?? "API"
      )}`}
    >
      <Icon type="arrow-up-down" className="size-3" />
      <div className="text-center text-xs font-medium leading-[18px]">
        {method ?? "API"}
      </div>
    </div>
  );
};
