import { useReactFlow } from "reactflow";
import { Workflow } from "../../../../models/Workflow";
import { CommonInputSegment } from "../../../Common/CommonInput";
import CommonDropdown, { Item } from "../CommonDropdown";
import { Team } from "../../../../models/Team";
import VariableInputSelector from "../VariableInputSelector";
import { WorkflowNodeData } from "../../Map/Nodes/WorkflowNode";

export const SidebarWorkflowDetail: React.FC<{
  workflow: Workflow;
  team: Team;
  data: WorkflowNodeData;
  workflows: Workflow[];
}> = ({ workflow, data, workflows }) => {
  const { setNodes } = useReactFlow();

  const updateNode = (newData: Partial<WorkflowNodeData>) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === data.id) {
          return { ...node, data: { ...node.data, ...newData } };
        }
        return node;
      })
    );
  };

  const selectedWorkflowItem = (): Item | undefined => {
    if (data.currentWorkflow) {
      return { id: data.currentWorkflow.id!, title: data.currentWorkflow.name };
    }
  };

  const inputs = Object.values(data.currentWorkflow?.usedVariables ?? {}).map(
    (v) => v.id
  );

  return (
    <div className="flex flex-col gap-2">
      <CommonInputSegment
        title="Agent Step Title"
        onChange={(t) => updateNode({ title: t })}
        value={data.title}
        placeholder="Step Title"
        error={undefined}
        setError={undefined}
        id="beamTitle"
        className="font-sans text-gray-700"
      />

      <div className="grid grid-cols-2 gap-2">
        <div className="flex flex-col gap-1">
          <div className="text-sm text-gray-700 font-medium">Workflow beam</div>
          <CommonDropdown
            items={workflows.map((w) => {
              return { id: w.id!, title: w.name };
            })}
            selectedItem={selectedWorkflowItem()}
            placeholder="Select"
            setSelectedItem={(item) => {
              const workflow = workflows.filter((p) => p.id == item.id)[0];
              updateNode({
                currentWorkflow: workflow,
              });
            }}
          />
        </div>
      </div>
      <div className="border gray-200 w-full my-2"></div>
      {inputs.map((v) => {
        return (
          <div className="flex flex-col">
            <div className="text-gray-700 text-sm font-medium">{`input for ${v}`}</div>
            <VariableInputSelector
              key={v}
              availableVariables={Object.values(
                workflow.usedVariables ?? {}
              ).map((uv) => uv.id)}
              selectedInput={
                (data.selectedInputs ?? {})[v] ?? { type: "variable" }
              }
              setSelectedInput={(input) => {
                updateNode({
                  selectedInputs: {
                    ...(data.selectedInputs ?? {}),
                    [v]: input,
                  },
                });
              }}
              currentNodeId={data.id}
            />
          </div>
        );
      })}
    </div>
  );
};
